import { makeStyles } from 'tss-react/mui';

export const useEditPlanDialogStyles = makeStyles()(() => ({
  section: {
    gap: '20px',
  },
  divider: {
    marginBottom: '16px',
  },
  item: {
    '&.MuiStack-root': {
      flex: 0.55,
      gap: '10px',
    },
    '&.MuiTypography-root': {
      flex: 0.45,
      fontWeight: 500,
    },
  },
}));
