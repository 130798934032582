import { ChangeEventHandler, useId, useMemo, useRef, useState } from 'react';

import { Theme } from '@emotion/react';
import AttachFileIcon from '@mui/icons-material/AttachFile';
import OndemandVideoIcon from '@mui/icons-material/OndemandVideo';
import PermMediaOutlinedIcon from '@mui/icons-material/PermMediaOutlined';
import { SxProps } from '@mui/material';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Fab from '@mui/material/Fab';
import FormControl from '@mui/material/FormControl';
import FormHelperText from '@mui/material/FormHelperText';
import Typography from '@mui/material/Typography';
import { useSnackbar } from 'notistack';
import { Control, FieldValues, Path, useWatch } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { API_MEDIA } from '~/constants/common';
import { ReactComponent as RefreshIcon } from '~/icons/images/refresh.svg';

const useStyles = makeStyles()((theme) => ({
  root: {
    width: '100%',
    marginTop: 0,
    marginBottom: 0,
    height: 'inherit',
  },
  wrap: {
    width: '100%',
    position: 'relative',
  },
  selectArea: {
    width: '100%',
    height: '100%',
    display: 'flex',
    flexWrap: 'wrap',
    textTransform: 'none',
    borderStyle: 'dashed',
    flexDirection: 'column',
    color: 'rgba(0, 0, 0, 0.87)',
    ':hover': {
      borderStyle: 'dashed',
    },
  },
  filePreview: {
    borderRadius: 4,
    maxWidth: '100%',
    maxHeight: '100%',
    overflow: 'hidden',
    position: 'absolute',
    backgroundColor: 'white',
    border: '1px dashed #ccc',
    margin: 0,
    top: 0,
    left: 0,
    right: 0,
    bottom: 0,
  },
  file: {
    width: '100%',
    height: '100%',
    objectFit: 'cover',
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  changeFile: {
    position: 'absolute',
    zIndex: 2,
    backgroundColor: 'white',
    right: theme.spacing(1),
    top: theme.spacing(1),
  },
}));

interface Props<T extends FieldValues> {
  name: Path<T>;
  height: string;
  label?: string;
  error?: boolean;
  disabled?: boolean;
  required?: boolean;
  className?: string;
  sx?: SxProps<Theme>;
  control: Control<T>;
  type: 'image' | 'video';
  defaultImagePath?: string;
  defaultImageUrlName: Path<T>;
  helperText?: React.ReactNode;
  onChange?: (files: FileList) => void;
  aspect?: number;
  cropTitle?: string;
}

const ImageUploadInput = <T extends FieldValues>(props: Props<T>) => {
  const {
    sx,
    name,
    type,
    error,
    label,
    height,
    control,
    required,
    disabled,
    className,
    helperText,
    defaultImagePath,
    defaultImageUrlName,
  } = props;
  const inputId = useId();
  const ref2: any = useRef();
  const { t } = useTranslation();

  const files = useWatch({
    control,
    name,
  }) as FileList;

  const defaultImageUrl = useWatch({
    control,
    name: defaultImageUrlName,
  });

  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const fileInfo = useMemo(() => {
    if (files && files.length) {
      const url = URL.createObjectURL(files[0]);
      return { url, name: files[0].name };
    }
    return undefined;
  }, [files]);

  const { onChange, ref, ...inputProps } = control.register(name);

  const content = useMemo(() => {
    if (type === 'image') {
      return {
        icon: <PermMediaOutlinedIcon />,
        inputAccept: 'image/*',
        label: t('select_image_file'),
        preview: (
          <img
            className={classes.file}
            src={
              fileInfo?.url ??
              (defaultImageUrl
                ? defaultImageUrl.includes(API_MEDIA)
                  ? defaultImageUrl
                  : API_MEDIA + defaultImageUrl
                : defaultImagePath)
            }
            draggable={false}
            alt=""
          />
        ),
      };
    }
    if (type === 'video') {
      return {
        icon: <OndemandVideoIcon />,
        label: t('select_video_file'),
        inputAccept: 'video/*',
        preview: <video className={classes.file} src={fileInfo?.url ?? defaultImageUrl} controls />,
      };
    }
    return {
      icon: <AttachFileIcon />,
      label: t('select_file'),
      inputAccept: undefined,
      preview: <div className={classes.file}>{fileInfo?.name}</div>,
    };
  }, [classes.file, fileInfo?.name, fileInfo?.url, t, type, defaultImageUrl, defaultImagePath]);

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    const { files } = event.target;
    if (files && files.length > 0) {
      const allowedTypes = [
        'image/jpeg',
        'image/png',
        'image/gif',
        'image/apng',
        'image/avif',
        'image/svg+xml',
        'image/webp',
      ];

      if (!allowedTypes.includes(files[0].type)) {
        enqueueSnackbar(t('file_not_supported'), { variant: 'error' });
        return;
      }

      if (props.onChange) {
        props.onChange(files);
      } else {
        onChange(event);
      }
    }
  };

  return (
    <>
      <FormControl className={classes.root} sx={sx} margin="normal">
        <Box height={height} className={`${classes.wrap} ${className ? className : ''}`}>
          <Button
            variant="outlined"
            color={error ? 'error' : 'primary'}
            component="label"
            className={classes.selectArea}
          >
            <input
              hidden
              type="file"
              id={inputId}
              disabled={disabled}
              accept={content.inputAccept}
              {...inputProps}
              onChange={handleChange}
              ref={props.onChange ? ref2 : ref}
            />
            <Box display="flex" marginBottom="5px">
              {content.icon}
            </Box>
            {!!label && (
              <Box width="100%" textAlign="center">
                <Typography sx={{ marginLeft: 1, fontSize: '12px' }}>{`${label}${required ? '*' : ''}`}</Typography>
              </Box>
            )}
          </Button>
          {!!(fileInfo || defaultImageUrl || defaultImagePath) && (
            <div className={classes.filePreview}>
              {!disabled && (
                <Fab size="small" component="label" className={classes.changeFile} htmlFor={inputId}>
                  <RefreshIcon />
                </Fab>
              )}
              {content.preview}
            </div>
          )}
        </Box>
        {!!helperText && <FormHelperText error={error}>{helperText}</FormHelperText>}
      </FormControl>
    </>
  );
};

export default ImageUploadInput;
