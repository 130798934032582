import { useMemo } from 'react';

import Box from '@mui/material/Box';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import { organizationStatus } from '../..';

import CustomCardTable from '~/components/custom-card-table';
import LoaderCenter from '~/components/loader-center';
import { CHIP_COLOR } from '~/constants/common';
import { Organization } from '~/graphql/admin/types';

interface OrganizationInfoBoxProps {
  organizationData: Organization;
}

const useStyles = makeStyles()((theme) => ({
  wrapper: {
    paddingBottom: '24px',
    '.img': {
      width: '100%',
      height: '100%',
      objectFit: 'cover',
    },
    '.MuiCardContent-root': {
      gap: '16px',
      display: 'flex',
      '&:last-child': {
        paddingBottom: '16px!important',
      },
      [theme.breakpoints.down('sm')]: {
        flexDirection: 'column',
      },
      '.MuiTypography-h5': {
        fontWeight: 500,
        fontSize: '10px',
        lineHeight: '16px',
        color: '#00000099',
        letterSpacing: '1.5px',
      },
      '.cardContent': {
        width: '100%',
        display: 'grid',
        columnGap: '16px',
        gridTemplateColumns: '1fr 1fr',
        '&__item': {
          width: '100%',
          display: 'flex',
          padding: '12px 16px',
          flexDirection: 'column',
          alignItems: 'flex-start',
          borderBottom: '1px solid #D7D7D7',
          '.MuiTypography-subtitle2': {
            fontWeight: 500,
            fontSize: '10px',
            lineHeight: '16px',
            color: '#00000099',
            letterSpacing: '1.5px',
          },
          '.MuiTypography-body2': {
            fontWeight: 400,
            fontSize: '16px',
            overflow: 'auto',
            minHeight: '24px',
            lineHeight: '24px',
            color: '#000000DE',
            whiteSpace: 'pre-wrap',
            wordBreak: 'break-word',
            letterSpacing: '0.15px',
          },
        },
      },
    },
    '.MuiCardActions-root': {
      padding: '16px',
      justifyContent: 'flex-end',
    },
  },
}));

const CollectionDetailInfo: React.FC<OrganizationInfoBoxProps> = (props) => {
  const { organizationData } = props;
  const { t } = useTranslation();
  const { classes } = useStyles();

  const listOrganizationData = useMemo(
    () => [
      {
        id: 1,
        label: t('organization_name'),
        element: <Typography variant="body2">{organizationData.name}</Typography>,
      },
      {
        id: 2,
        label: t('contact_email'),
        element: <Typography variant="body2">{organizationData.contactEmail}</Typography>,
      },
      {
        id: 3,
        label: t('base_currency'),
        element: <Typography variant="body2">{organizationData.currencySetting?.baseCurrency}</Typography>,
      },
      {
        id: 4,
        label: t('category'),
        element: <Typography variant="body2">{organizationData.operation?.type}</Typography>,
      },
      {
        id: 5,
        label: t('address'),
        element: (
          <Typography variant="body2">
            {[
              organizationData.operation?.address?.postalCode || '',
              organizationData.operation?.address?.prefecture || '',
              organizationData.operation?.address?.address || '',
            ]
              .join(' ')
              .trim()}
          </Typography>
        ),
      },
      {
        id: 6,
        label: t('phone_number'),
        element: <Typography variant="body2">{organizationData.operation?.contact?.phoneNumber}</Typography>,
      },
    ],
    [t, organizationData]
  );

  return (
    <Box>
      {!organizationData ? (
        <LoaderCenter />
      ) : (
        <Box className={classes.wrapper}>
          <CustomCardTable
            cardTitle={t('basic')}
            headerAction={
              <Chip
                variant="filled"
                color={CHIP_COLOR[organizationData.status]}
                label={`${organizationStatus(t)[organizationData.status]?.title}` || '-'}
              />
            }
            cardContent={
              <Box className="cardContent">
                {listOrganizationData.map((info) => (
                  <Box key={info.id} className="cardContent__item">
                    <Typography variant="subtitle2">{info.label ?? ''}</Typography>
                    {info.element}
                  </Box>
                ))}
              </Box>
            }
          />
        </Box>
      )}
    </Box>
  );
};

export default CollectionDetailInfo;
