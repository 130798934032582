import { TFunction } from 'i18next';
import web3 from 'web3';
import * as yup from 'yup';

export const getErrorText = (errors: any, t: TFunction, label?: string) => {
  return typeof errors === 'object'
    ? (t(errors?.key, {
        ...errors?.values,
        label: t(errors?.values?.label || label),
      }) as string)
    : t(errors);
};

yup.addMethod(yup.string, 'ethAddress', function (message?: string) {
  return this.test({
    name: 'isEthAddress',
    message: message || `form_validation.isEthAddress`,
    test: (value) => {
      return value ? web3.utils.isAddress(value) : true;
    },
  });
});

yup.setLocale({
  mixed: {
    required: 'form_validation.this_field_cannot_be_empty',
  },
  string: {
    max: (values) => ({ key: 'form_validation.max', values }),
  },
  number: {
    min: (values) => ({ key: 'form_validation.number_min', values }),
    max: (values) => ({ key: 'form_validation.number_max', values }),
  },
});

declare module 'yup' {
  interface StringSchema {
    ethAddress(message?: string): this;
  }
  interface StringLocale {
    ethAddress: string;
  }
}
