import { useCallback, useEffect, useMemo, useState } from 'react';

import NavigateNextIcon from '@mui/icons-material/NavigateNext';
import { Button } from '@mui/material';
import Box from '@mui/material/Box';
import Breadcrumbs from '@mui/material/Breadcrumbs';
import Chip from '@mui/material/Chip';
import Typography from '@mui/material/Typography';
import { GridColDef, jaJP } from '@mui/x-data-grid';
import { TFunction } from 'i18next';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import CustomCardTable from '~/components/custom-card-table';
import HomeBtn from '~/components/home-btn';
import ListTable, { ListTablePagination } from '~/components/list-table';
import { CHIP_COLOR, ITEMS_PER_PAGE } from '~/constants/common';
import { AppRouteEnum } from '~/enum/AppRouteEnum';
import {
  ListOrganizationsDocument,
  OrganizationQueryKey,
  OrganizationStatus,
  useListOrganizationsQuery,
  useUpdateOrganizationStatusMutation,
} from '~/graphql/admin/types';
import { generatePathname, getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

export interface DisplayedOrganizationsData {
  id: string;
  [OrganizationQueryKey.CreatedAt]: Date;
  [OrganizationQueryKey.Status]: OrganizationStatus;
  [OrganizationQueryKey.Name]: string | null | undefined;
  [OrganizationQueryKey.ContactEmail]: string | null | undefined;
}

const getInitQuery = {
  page: 1,
  limit: ITEMS_PER_PAGE,
  searchText: '',
  sortBy: verifySortKey(OrganizationQueryKey, getLocalStorage('organizations_list_sort')),
  orderBy: verifyOrderKey(getLocalStorage('organizations_list_order')),
  where: {},
};

export const organizationStatus = (t: TFunction<'translation', undefined>) => {
  return {
    [OrganizationStatus.Approved]: {
      title: t('approved'),
      buttonTitle: t('block'),
      buttonColor: 'error' as 'error',
      value: OrganizationStatus.Approved,
    },
    [OrganizationStatus.Blocked]: {
      title: t('blocked'),
      buttonTitle: t('approve'),
      buttonColor: 'info' as 'info',
      value: OrganizationStatus.Blocked,
    },
    [OrganizationStatus.Requested]: {
      title: t('requested'),
      buttonTitle: t('approve'),
      buttonColor: 'info' as 'info',
      value: OrganizationStatus.Requested,
    },
  };
};

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
  wrapperTopTable: {
    display: 'flex',
    width: '100%',
    justifyContent: 'flex-end',
    alignItems: 'center',
    padding: '16px 8px 8px',
  },
  shopURL: {
    color: '#000000DE',
    textDecoration: 'none',
    '&:hover': {
      color: '#1976d2',
      textDecoration: 'underline',
    },
  },
  addBtn: {
    right: '16px',
    bottom: '16px',
    position: 'fixed',
    '.add-btn': {
      height: '56px',
      minWidth: '56px!important',
      borderRadius: '50%',
    },
  },
}));

const ListOrganizations = () => {
  const { classes } = useStyles();
  const { t, i18n } = useTranslation();
  const { enqueueSnackbar } = useSnackbar();

  useEffect(() => {
    document.title = t('list_organizations');
  }, [t]);

  const [organizationQuery, setOrganizationQuery] = useState(getInitQuery);

  useEffect(() => {
    setLocalStorageItems({
      organizations_list_order: organizationQuery?.orderBy,
      organizations_list_sort: organizationQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [organizationQuery?.orderBy, organizationQuery?.sortBy]);

  const updateOrganizationQuery = (newValue: any) => setOrganizationQuery((value: any) => ({ ...value, ...newValue }));

  const [updateOrganizationStatus] = useUpdateOrganizationStatusMutation({
    refetchQueries: [ListOrganizationsDocument],
    onCompleted: () => updateOrganizationQuery({ page: 1 }),
  });

  const { data: listOrganizationsRes, loading: loadingListOrganizations } = useListOrganizationsQuery({
    fetchPolicy: 'cache-and-network',
    variables: organizationQuery,
  });

  const { items, pagination } = useMemo(() => {
    const items = listOrganizationsRes?.listOrganizations?.items || [];
    const pagination: ListTablePagination = listOrganizationsRes?.listOrganizations?.pagination || {};

    const _items: DisplayedOrganizationsData[] = items.map((item) => {
      return {
        id: item.uuid,
        [OrganizationQueryKey.Name]: item.name,
        [OrganizationQueryKey.Status]: item.status,
        [OrganizationQueryKey.CreatedAt]: item.createdAt,
        [OrganizationQueryKey.ContactEmail]: item.contactEmail,
      } as DisplayedOrganizationsData;
    });
    return { items: _items, pagination };
  }, [listOrganizationsRes?.listOrganizations?.items, listOrganizationsRes?.listOrganizations?.pagination]);

  const handleUpdateStatus = useCallback(
    (uuid: string, status: OrganizationStatus) => async () => {
      try {
        let newStatus;
        switch (status) {
          case OrganizationStatus.Blocked:
          case OrganizationStatus.Requested:
            newStatus = OrganizationStatus.Approved;
            break;
          default:
            newStatus = OrganizationStatus.Blocked;
            break;
        }
        await updateOrganizationStatus({
          variables: {
            input: {
              uuid,
              status: newStatus,
            },
          },
        });
        enqueueSnackbar(t('toast_message.updated_successfully'), { variant: 'success' });
      } catch (err: any) {
        enqueueSnackbar(t('toast_message.error'), { variant: 'error' });
      }
    },
    [t, updateOrganizationStatus, enqueueSnackbar]
  );

  const columns: GridColDef<DisplayedOrganizationsData>[] = useMemo(
    () => [
      {
        width: 300,
        headerName: t('name.thing'),
        field: OrganizationQueryKey.Name,
        renderCell: ({ row, value }) => (
          <Link
            style={{ color: 'rgba(0, 0, 0, 0.87)' }}
            to={generatePathname(AppRouteEnum.OrganizationDetail, { id: row.id })}
          >
            {value}
          </Link>
        ),
      },
      {
        width: 300,
        headerName: t('contact_email'),
        field: OrganizationQueryKey.ContactEmail,
      },
      {
        width: 120,
        headerName: t('status'),
        field: OrganizationQueryKey.Status,
        renderCell: ({ row }) => {
          const status: OrganizationStatus = row[OrganizationQueryKey.Status] || OrganizationStatus.Blocked;
          return (
            <Chip
              variant="filled"
              color={CHIP_COLOR[status]}
              label={`${organizationStatus(t)[status]?.title}` || '-'}
            />
          );
        },
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: OrganizationQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 120,
        headerName: '',
        type: 'actions',
        sortable: false,
        resizable: false,
        disableReorder: true,
        field: t('actions'),
        getActions: ({ row }) => {
          const status: OrganizationStatus = row[OrganizationQueryKey.Status] || OrganizationStatus.Blocked;
          const info = organizationStatus(t)[status];
          return [
            <Button variant="contained" color={info.buttonColor} onClick={handleUpdateStatus(row.id, status)}>
              {info?.buttonTitle}
            </Button>,
          ];
        },
      },
    ],
    [t, handleUpdateStatus]
  );

  return (
    <UserLayout>
      <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
        <HomeBtn />
        <Typography color="text.secondary">{t('list_organizations')}</Typography>
      </Breadcrumbs>
      <Box className={classes.wrapper}>
        <CustomCardTable
          cardTitle={t('list_organizations')}
          cardContent={
            <ListTable
              notSquare
              noBorder
              rows={items}
              columns={columns}
              tableName="list_organizations"
              searchLabel={t('name.thing')}
              isLoading={loadingListOrganizations}
              localeText={i18n.language === 'ja' ? jaJP.components.MuiDataGrid.defaultProps.localeText : undefined}
              search={organizationQuery.searchText}
              onSearch={(v) => updateOrganizationQuery({ page: 1, searchText: v || '' })}
              paginationData={pagination}
              onPagination={updateOrganizationQuery}
              sort={{
                sortBy: organizationQuery.sortBy,
                orderBy: organizationQuery.orderBy,
              }}
              onSort={updateOrganizationQuery}
            />
          }
        />
      </Box>
    </UserLayout>
  );
};

export default ListOrganizations;
