import { gql } from '@apollo/client';
import * as Apollo from '@apollo/client';
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
const defaultOptions = { context: { apiName: 'admin' } } as const;
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  Date: any;
  Upload: any;
};

export type AcquisitionInformation = {
  displayName?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  required?: Maybe<Scalars['Boolean']>;
  type?: Maybe<Scalars['String']>;
};

export type AddAdminInput = {
  email: Scalars['String'];
  role?: AdminRole;
};

export type Admin = {
  createdAt: Scalars['Date'];
  displayName?: Maybe<Scalars['String']>;
  email: Scalars['String'];
  role: AdminRole;
  /** Firebase uid */
  uid?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export enum AdminRole {
  Administrator = 'administrator',
  Manager = 'manager',
  Operator = 'operator',
  Owner = 'owner',
}

export type ChainNetwork = {
  blockExplorer?: Maybe<Scalars['String']>;
  chainId: Scalars['String'];
  contractAddress?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  icon?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  rpcUrl: Scalars['String'];
  subgraphUrl?: Maybe<Scalars['String']>;
  testMode?: Maybe<Scalars['Boolean']>;
  tokenName?: Maybe<Scalars['String']>;
  tokenSymbol?: Maybe<Scalars['String']>;
  tokenSymbols?: Maybe<Array<Scalars['String']>>;
  uuid: Scalars['String'];
  walletAddress?: Maybe<Scalars['String']>;
};

export type Collection = {
  contractAddress: Scalars['String'];
  createdAt: Scalars['Date'];
  desc?: Maybe<Scalars['String']>;
  descJa?: Maybe<Scalars['String']>;
  images?: Maybe<Array<Scalars['String']>>;
  name: Scalars['String'];
  nameJa?: Maybe<Scalars['String']>;
  network: Scalars['String'];
  organizationUuid: Scalars['String'];
  ownerAddress?: Maybe<Scalars['String']>;
  ownerUid: Scalars['String'];
  symbol: Scalars['String'];
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export enum CollectionQueryKey {
  ContractAddress = 'CONTRACT_ADDRESS',
  CreatedAt = 'CREATED_AT',
  Desc = 'DESC',
  DescJa = 'DESC_JA',
  Name = 'NAME',
  NameJa = 'NAME_JA',
  Network = 'NETWORK',
  OwnerAddress = 'OWNER_ADDRESS',
  Symbol = 'SYMBOL',
  Type = 'TYPE',
  Uuid = 'UUID',
}

export type CollectionsFilterField = {
  key: CollectionQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type Contact = {
  phoneNumber?: Maybe<Scalars['String']>;
  publish?: Maybe<Scalars['Boolean']>;
};

export type CreateChainNetworkInput = {
  blockExplorer: Scalars['String'];
  chainId: Scalars['String'];
  contractAddress?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  /** File */
  iconFile?: InputMaybe<Scalars['Upload']>;
  name: Scalars['String'];
  rpcUrl: Scalars['String'];
  subgraphUrl: Scalars['String'];
  testMode: Scalars['Boolean'];
  tokenName?: InputMaybe<Scalars['String']>;
  tokenSymbol?: InputMaybe<Scalars['String']>;
  tokenSymbols?: InputMaybe<Array<Scalars['String']>>;
};

export enum Currency {
  Jpy = 'JPY',
  Usd = 'USD',
}

export type CurrencySetting = {
  baseCurrency?: Maybe<Currency>;
};

export type Dashboard = {
  totalAccount: Scalars['Float'];
  totalCollection: Scalars['Float'];
  totalMemberSite: Scalars['Float'];
  totalOrganization: Scalars['Float'];
  totalShop: Scalars['Float'];
};

export type DetailMyShopCollectionImage = {
  collectionImageUuid?: Maybe<Scalars['String']>;
  collectionUuid: Scalars['String'];
  createdAt: Scalars['Date'];
  currentMint?: Maybe<Scalars['Float']>;
  image?: Maybe<Scalars['String']>;
  maxMint?: Maybe<Scalars['Float']>;
  myShopCollectionImageName?: Maybe<Scalars['String']>;
  myShopCollectionImageNameJa?: Maybe<Scalars['String']>;
  myShopCollectionImageUuid?: Maybe<Scalars['String']>;
  myShopCollectionUuid?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  status?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
};

export type Domain = {
  customDomain?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subDomain?: Maybe<Scalars['String']>;
};

export type IAddress = {
  address?: Maybe<Scalars['String']>;
  addressDisplay?: Maybe<Scalars['Boolean']>;
  postalCode?: Maybe<Scalars['String']>;
  prefecture?: Maybe<Scalars['String']>;
};

export type Invoice = {
  amountDue: Scalars['Float'];
  amountPaid: Scalars['Float'];
  amountRemaining: Scalars['Float'];
  createdAt: Scalars['Date'];
  currency: Scalars['String'];
  id?: Maybe<Scalars['String']>;
  items: Array<InvoiceItem>;
  metadata?: Maybe<MetadataInvoice>;
  paymentIntent?: Maybe<PaymentIntent>;
  periodEnd: Scalars['Date'];
  periodStart: Scalars['Date'];
  status?: Maybe<Scalars['String']>;
  subtotal: Scalars['Float'];
  subtotalExcludingTax?: Maybe<Scalars['Float']>;
  total: Scalars['Float'];
  totalExcludingTax?: Maybe<Scalars['Float']>;
};

export type InvoiceItem = {
  amount?: Maybe<Scalars['Float']>;
  amountExcludingTax?: Maybe<Scalars['Float']>;
  currency?: Maybe<Scalars['String']>;
  description: Scalars['String'];
  id: Scalars['String'];
  invoiceItemId?: Maybe<Scalars['String']>;
  priceId?: Maybe<Scalars['String']>;
  priceType?: Maybe<Scalars['String']>;
  quantity?: Maybe<Scalars['Float']>;
  subscriptionId?: Maybe<Scalars['String']>;
};

export type IpfsStorage = {
  apiKey: Scalars['String'];
  storage: Scalars['String'];
};

export type ListCollectionsFilter = {
  availableShopUuid?: InputMaybe<Scalars['String']>;
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<CollectionsFilterField>>;
};

export type ListCollectionsResponse = {
  items: Array<Collection>;
  pagination: Pagination;
};

export type ListMyShopCollectionsFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MyShopCollectionsFilterField>>;
  shopType?: InputMaybe<Scalars['String']>;
};

export type ListMyShopCollectionsResponse = {
  items: Array<MyShopCollectionDetail>;
  pagination: Pagination;
};

export type ListMyShopResponse = {
  items: Array<MyShop>;
  pagination: Pagination;
};

export type ListMyShopsFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<MyShopFilterField>>;
};

export type ListOrganizationsFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<OrganizationFilterField>>;
};

export type ListOrganizationsResponse = {
  items: Array<Organization>;
  pagination: Pagination;
};

export type ListUsersFilter = {
  condition?: InputMaybe<QueryMethod>;
  fields?: InputMaybe<Array<UserFilterField>>;
};

export type ListUsersResponse = {
  items: Array<User>;
  pagination: Pagination;
};

export type Member = {
  address?: Maybe<IAddress>;
  createdAt: Scalars['Date'];
  email?: Maybe<Scalars['String']>;
  firstName?: Maybe<Scalars['String']>;
  language?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  ownerUid?: Maybe<Scalars['String']>;
  shopUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  verifyEmail?: Maybe<Scalars['Boolean']>;
  wallet?: Maybe<Scalars['String']>;
};

export type MemberToken = {
  collectionUuid?: Maybe<Scalars['String']>;
  createdAt: Scalars['Date'];
  ownerUid?: Maybe<Scalars['String']>;
  shopUuid?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
  tokenUuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  wallet?: Maybe<Scalars['String']>;
};

export type MetadataContent = {
  animation_url?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  image: Scalars['String'];
  name: Scalars['String'];
};

export type MetadataInvoice = {
  organizationUuid?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid?: Maybe<Scalars['String']>;
};

export type Mutation = {
  addAdmin: Admin;
  addPlan: Plan;
  addRevenueShare: RevenueShare;
  createNetwork: ChainNetwork;
  removeAdmin: Scalars['Boolean'];
  removeNetwork: Scalars['Boolean'];
  removePlan: Scalars['Boolean'];
  removeRevenueShare: Scalars['Boolean'];
  updateAdmin: Admin;
  updateNetwork: ChainNetwork;
  updateOrganizationStatus: Organization;
  updatePlan: Plan;
  updateRevenueShare: RevenueShare;
  updateSystemSettings: Scalars['Boolean'];
};

export type MutationAddAdminArgs = {
  input: AddAdminInput;
};

export type MutationAddPlanArgs = {
  input: PlanInput;
};

export type MutationAddRevenueShareArgs = {
  input: RevenueShareInput;
};

export type MutationCreateNetworkArgs = {
  input: CreateChainNetworkInput;
};

export type MutationRemoveAdminArgs = {
  uuid: Scalars['String'];
};

export type MutationRemoveNetworkArgs = {
  uuid: Scalars['String'];
};

export type MutationRemovePlanArgs = {
  uuid: Scalars['String'];
};

export type MutationRemoveRevenueShareArgs = {
  uuid: Scalars['String'];
};

export type MutationUpdateAdminArgs = {
  input: UpdateAdminInput;
};

export type MutationUpdateNetworkArgs = {
  input: UpdateChainNetworkInput;
};

export type MutationUpdateOrganizationStatusArgs = {
  input: UpdateOrganizationStatusInput;
};

export type MutationUpdatePlanArgs = {
  input: UpdatePlanInput;
};

export type MutationUpdateRevenueShareArgs = {
  input: UpdateRevenueShareInput;
};

export type MutationUpdateSystemSettingsArgs = {
  input: SystemSettingInput;
};

export type MyShop = {
  createdAt: Scalars['Date'];
  domain?: Maybe<Domain>;
  hasUpdate?: Maybe<Scalars['Boolean']>;
  nftActivationSettings?: Maybe<NftActivationSettings>;
  operation?: Maybe<Operation>;
  organizationUuid: Scalars['String'];
  ownerUid: Scalars['String'];
  paymentMethod?: Maybe<PaymentMethod>;
  publish?: Maybe<Scalars['Boolean']>;
  shopInformation?: Maybe<ShopInformation>;
  shopType?: Maybe<Scalars['String']>;
  siteSetting?: Maybe<SiteSetting>;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type MyShopCollectionDetail = {
  collection?: Maybe<Collection>;
  collectionImages?: Maybe<Array<DetailMyShopCollectionImage>>;
  collectionUuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  desc?: Maybe<Scalars['String']>;
  descJa?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  nameJa?: Maybe<Scalars['String']>;
  order?: Maybe<Scalars['Float']>;
  orderBy?: Maybe<Scalars['String']>;
  shopUuid?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid?: Maybe<Scalars['String']>;
};

export enum MyShopCollectionQueryKey {
  CollectionContractAddress = 'COLLECTION_CONTRACT_ADDRESS',
  CollectionCreatedAt = 'COLLECTION_CREATED_AT',
  CollectionDesc = 'COLLECTION_DESC',
  CollectionDescJa = 'COLLECTION_DESC_JA',
  CollectionName = 'COLLECTION_NAME',
  CollectionNameJa = 'COLLECTION_NAME_JA',
  CollectionNetwork = 'COLLECTION_NETWORK',
  CollectionOwnerAddress = 'COLLECTION_OWNER_ADDRESS',
  CollectionSymbol = 'COLLECTION_SYMBOL',
  CollectionType = 'COLLECTION_TYPE',
  CollectionUuid = 'COLLECTION_UUID',
  CreatedAt = 'CREATED_AT',
  Desc = 'DESC',
  DescJa = 'DESC_JA',
  Name = 'NAME',
  NameJa = 'NAME_JA',
  ShopUuid = 'SHOP_UUID',
  Status = 'STATUS',
  Type = 'TYPE',
  Uuid = 'UUID',
}

export type MyShopCollectionsFilterField = {
  key: MyShopCollectionQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export type MyShopFilterField = {
  key: MyShopQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export enum MyShopQueryKey {
  CreatedAt = 'CREATED_AT',
  Publish = 'PUBLISH',
  ShopType = 'SHOP_TYPE',
  Title = 'TITLE',
  Uuid = 'UUID',
}

export type Navi = {
  description?: Maybe<Scalars['String']>;
  descriptionJa?: Maybe<Scalars['String']>;
  pageTitle?: Maybe<Scalars['String']>;
  pageTitleJa?: Maybe<Scalars['String']>;
};

export type NftActivationSettings = {
  acquisitionInformation: Array<AcquisitionInformation>;
  expireDate?: Maybe<Scalars['Date']>;
  expireMethod?: Maybe<Scalars['String']>;
  expireUnit?: Maybe<Scalars['String']>;
  expireValue?: Maybe<Scalars['String']>;
};

export type Operation = {
  address?: Maybe<IAddress>;
  contact?: Maybe<Contact>;
  dayOfBirth?: Maybe<Scalars['Date']>;
  detailName?: Maybe<OperatorName>;
  name?: Maybe<Scalars['String']>;
  sex?: Maybe<Scalars['String']>;
  shopName?: Maybe<ShopName>;
  type?: Maybe<Scalars['String']>;
};

export type OperatorName = {
  firstName?: Maybe<Scalars['String']>;
  firstNameEn?: Maybe<Scalars['String']>;
  firstNameKana?: Maybe<Scalars['String']>;
  lastName?: Maybe<Scalars['String']>;
  lastNameEn?: Maybe<Scalars['String']>;
  lastNameKana?: Maybe<Scalars['String']>;
};

export enum OrderBy {
  Asc = 'ASC',
  Desc = 'DESC',
}

export type Organization = {
  contactEmail: Scalars['String'];
  createdAt: Scalars['Date'];
  currencySetting?: Maybe<CurrencySetting>;
  ipfsStorageApiKeys: Array<IpfsStorage>;
  masterWalletAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operation?: Maybe<Operation>;
  paymentSetting?: Maybe<PaymentSetting>;
  planSetting?: Maybe<PlanSetting>;
  pointSetting?: Maybe<PointSetting>;
  region?: Maybe<Scalars['String']>;
  status: OrganizationStatus;
  totalMember: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type OrganizationFilterField = {
  key: OrganizationQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export enum OrganizationQueryKey {
  ContactEmail = 'CONTACT_EMAIL',
  CreatedAt = 'CREATED_AT',
  Name = 'NAME',
  Status = 'STATUS',
  Uuid = 'UUID',
}

export type OrganizationRoleUser = {
  contactEmail: Scalars['String'];
  createdAt: Scalars['Date'];
  currencySetting?: Maybe<CurrencySetting>;
  ipfsStorageApiKeys: Array<IpfsStorage>;
  masterWalletAddress?: Maybe<Scalars['String']>;
  name: Scalars['String'];
  operation?: Maybe<Operation>;
  paymentSetting?: Maybe<PaymentSetting>;
  planSetting?: Maybe<PlanSetting>;
  pointSetting?: Maybe<PointSetting>;
  region?: Maybe<Scalars['String']>;
  role: OrganizationUserRole;
  status: OrganizationStatus;
  totalMember: Scalars['Float'];
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export enum OrganizationStatus {
  Approved = 'APPROVED',
  Blocked = 'BLOCKED',
  Requested = 'REQUESTED',
}

export type OrganizationUser = {
  displayName?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  role: OrganizationUserRole;
  uid: Scalars['String'];
};

export enum OrganizationUserRole {
  Admin = 'admin',
  Member = 'member',
  Owner = 'owner',
  Viewer = 'viewer',
}

export type Pagination = {
  /** Current page */
  currentPage: Scalars['Int'];
  /** Items per page */
  itemsPerPage: Scalars['Int'];
  /** Total items */
  totalItems: Scalars['Int'];
  /** Total pages */
  totalPages: Scalars['Int'];
};

export type PaymentIntent = {
  amount: Scalars['Float'];
  createdAt: Scalars['Date'];
  currency: Scalars['String'];
  description?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  paymentMethod?: Maybe<PaymentMethodStripe>;
  status?: Maybe<Scalars['String']>;
};

export type PaymentMethod = {
  baseCurrency?: Maybe<Currency>;
  enableCard?: Maybe<Scalars['Boolean']>;
  publicKey?: Maybe<Scalars['String']>;
  secretKey?: Maybe<Scalars['String']>;
};

export type PaymentMethodStripe = {
  brand: Scalars['String'];
  exp_month?: Maybe<Scalars['String']>;
  exp_year?: Maybe<Scalars['String']>;
  id: Scalars['String'];
  last4: Scalars['String'];
  name: Scalars['String'];
  object: Scalars['String'];
  type: Scalars['String'];
};

export type PaymentSetting = {
  isLiveMode?: Maybe<Scalars['Boolean']>;
  policyUrl?: Maybe<Scalars['String']>;
  publicKey?: Maybe<Scalars['String']>;
  requiredAcceptTerms?: Maybe<Scalars['Boolean']>;
  secretKey?: Maybe<Scalars['String']>;
  termsUrl?: Maybe<Scalars['String']>;
};

export type Plan = {
  additionalMemberFee?: Maybe<Scalars['Float']>;
  additionalShopFee?: Maybe<Scalars['Float']>;
  additionalSiteFee?: Maybe<Scalars['Float']>;
  additionalUserFee?: Maybe<Scalars['Float']>;
  agentFeeRatio?: Maybe<Scalars['Float']>;
  annualFee?: Maybe<Scalars['Float']>;
  availableForSubscribe?: Maybe<Scalars['Boolean']>;
  createdAt: Scalars['Date'];
  currency?: Maybe<Currency>;
  customDomain?: Maybe<Scalars['Boolean']>;
  displayLogo?: Maybe<Scalars['Boolean']>;
  initialAward?: Maybe<Scalars['Float']>;
  monthlyFee?: Maybe<Scalars['Float']>;
  numberOfAdminUsers?: Maybe<Scalars['Float']>;
  numberOfMemberPerSite?: Maybe<Scalars['Float']>;
  numberOfMemberSites?: Maybe<Scalars['Float']>;
  numberOfShops?: Maybe<Scalars['Float']>;
  planName?: Maybe<Scalars['String']>;
  pvLimit?: Maybe<Scalars['Float']>;
  revenueShareRatio?: Maybe<Scalars['Float']>;
  status?: Maybe<PlanStatus>;
  type?: Maybe<PlanType>;
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type PlanInput = {
  additionalMemberFee?: InputMaybe<Scalars['Float']>;
  additionalShopFee?: InputMaybe<Scalars['Float']>;
  additionalSiteFee?: InputMaybe<Scalars['Float']>;
  additionalUserFee?: InputMaybe<Scalars['Float']>;
  agentFeeRatio?: InputMaybe<Scalars['Float']>;
  annualFee?: InputMaybe<Scalars['Float']>;
  availableForSubscribe?: InputMaybe<Scalars['Boolean']>;
  currency?: InputMaybe<Currency>;
  customDomain?: InputMaybe<Scalars['Boolean']>;
  displayLogo?: InputMaybe<Scalars['Boolean']>;
  initialAward?: InputMaybe<Scalars['Float']>;
  monthlyFee?: InputMaybe<Scalars['Float']>;
  numberOfAdminUsers?: InputMaybe<Scalars['Float']>;
  numberOfMemberPerSite?: InputMaybe<Scalars['Float']>;
  numberOfMemberSites?: InputMaybe<Scalars['Float']>;
  numberOfShops?: InputMaybe<Scalars['Float']>;
  planName?: InputMaybe<Scalars['String']>;
  pvLimit?: InputMaybe<Scalars['Float']>;
  revenueShareRatio?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PlanStatus>;
  type?: InputMaybe<PlanType>;
};

export type PlanSetting = {
  expireAt?: Maybe<Scalars['Date']>;
  planUuid?: Maybe<Scalars['String']>;
};

export enum PlanStatus {
  Active = 'ACTIVE',
  Inactive = 'INACTIVE',
}

export enum PlanType {
  Custom = 'CUSTOM',
  Default = 'DEFAULT',
}

export type Point = {
  chainId: Scalars['String'];
  createdAt: Scalars['Date'];
  expireAt: Scalars['Date'];
  point: Scalars['Float'];
  type: PointType;
  usedPoint: Scalars['Float'];
  uuid: Scalars['String'];
};

export type PointSetting = {
  chainId?: Maybe<Scalars['String']>;
  enable?: Maybe<Scalars['Boolean']>;
  maxAmount?: Maybe<Scalars['Float']>;
  threshold?: Maybe<Scalars['Float']>;
};

export enum PointType {
  Award = 'AWARD',
  Plan = 'PLAN',
  Purchase = 'PURCHASE',
  Trans = 'TRANS',
}

export type Query = {
  countAll?: Maybe<Dashboard>;
  getMasterWalletAddress: Scalars['String'];
  getMe?: Maybe<Admin>;
  getMyShop: MyShop;
  getNetwork?: Maybe<ChainNetwork>;
  getOrganization: Organization;
  getPlan: Plan;
  getPlans: Array<Plan>;
  getRevenueShare: Array<RevenueShare>;
  getSystemSettings: SystemSettings;
  listAdmin: Array<Admin>;
  listMyShopCollections: ListMyShopCollectionsResponse;
  listNetworks: Array<ChainNetwork>;
  listOrganizationCollections: ListCollectionsResponse;
  listOrganizationShops: ListMyShopResponse;
  listOrganizationUsers: Array<OrganizationUser>;
  listOrganizations: ListOrganizationsResponse;
  listUsers: ListUsersResponse;
};

export type QueryGetMyShopArgs = {
  uuid: Scalars['String'];
};

export type QueryGetOrganizationArgs = {
  uuid: Scalars['String'];
};

export type QueryGetPlanArgs = {
  uuid: Scalars['String'];
};

export type QueryListMyShopCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MyShopCollectionQueryKey>;
  where: ListMyShopCollectionsFilter;
};

export type QueryListOrganizationCollectionsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  organizationUuid: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<CollectionQueryKey>;
  where?: InputMaybe<ListCollectionsFilter>;
};

export type QueryListOrganizationShopsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  organizationUuid: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<MyShopQueryKey>;
  where?: InputMaybe<ListMyShopsFilter>;
};

export type QueryListOrganizationUsersArgs = {
  organizationUuid: Scalars['String'];
};

export type QueryListOrganizationsArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<OrganizationQueryKey>;
  where?: InputMaybe<ListOrganizationsFilter>;
};

export type QueryListUsersArgs = {
  limit?: InputMaybe<Scalars['Int']>;
  orderBy?: InputMaybe<OrderBy>;
  page?: InputMaybe<Scalars['Int']>;
  searchText?: InputMaybe<Scalars['String']>;
  sortBy?: InputMaybe<UserQueryKey>;
  where?: InputMaybe<ListUsersFilter>;
};

export enum QueryMethod {
  And = 'AND',
  Or = 'OR',
}

export enum QueryOperator {
  Anyof = 'ANYOF',
  Contains = 'CONTAINS',
  Empty = 'EMPTY',
  End = 'END',
  Equals = 'EQUALS',
  Exists = 'EXISTS',
  Start = 'START',
}

export type RevenueShare = {
  createdAt: Scalars['Date'];
  currency: Currency;
  fee: Scalars['Float'];
  from: Scalars['Float'];
  planUuid?: Maybe<Scalars['String']>;
  unit: RevenueShareUnit;
  updatedAt: Scalars['Date'];
  uuid: Scalars['String'];
};

export type RevenueShareInput = {
  fee: Scalars['Float'];
  from: Scalars['Float'];
  unit: RevenueShareUnit;
};

export enum RevenueShareUnit {
  Percent = 'PERCENT',
  Usd = 'USD',
}

export type ShopInformation = {
  information?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  orderBy?: Maybe<Scalars['String']>;
  policy?: Maybe<Scalars['String']>;
  sortBy?: Maybe<Scalars['String']>;
  terms?: Maybe<Scalars['String']>;
};

export type ShopName = {
  name?: Maybe<Scalars['String']>;
  nameKana?: Maybe<Scalars['String']>;
};

export type SiteSetting = {
  banner?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  favicon?: Maybe<Scalars['String']>;
  logo?: Maybe<Scalars['String']>;
  meta?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ogp?: Maybe<Scalars['String']>;
  ratioBanner?: Maybe<Scalars['String']>;
  theme?: Maybe<Theme>;
  title?: Maybe<Scalars['String']>;
};

export type Style = {
  backgroundColor?: Maybe<Scalars['String']>;
  buttonColor?: Maybe<Scalars['String']>;
  collectionBorderColor?: Maybe<Scalars['String']>;
  collectionNftCardImageMode?: Maybe<Scalars['String']>;
  collectionTitleColor?: Maybe<Scalars['String']>;
  descriptionColor?: Maybe<Scalars['String']>;
  font?: Maybe<Scalars['String']>;
  headerColor?: Maybe<Scalars['String']>;
  headerTextColor?: Maybe<Scalars['String']>;
  nftCardBackgroundColor?: Maybe<Scalars['String']>;
  nftCardTextColor?: Maybe<Scalars['String']>;
  spacing?: Maybe<Scalars['Float']>;
  spacingSm?: Maybe<Scalars['Float']>;
  textColor?: Maybe<Scalars['String']>;
  titleColor?: Maybe<Scalars['String']>;
};

export type SystemSettingInput = {
  maximumPoint?: InputMaybe<Scalars['Float']>;
  paymentLiveMode?: InputMaybe<Scalars['Boolean']>;
  restrictedOrganization?: InputMaybe<Scalars['Boolean']>;
};

export type SystemSettings = {
  maximumPoint: Scalars['Float'];
  paymentLiveMode: Scalars['Boolean'];
  restrictedOrganization: Scalars['Boolean'];
};

export type Thema = {
  template?: Maybe<Scalars['String']>;
};

export type Theme = {
  navi?: Maybe<Navi>;
  positionLabelSample?: Maybe<Array<Scalars['Float']>>;
  showLabelSample?: Maybe<Scalars['Boolean']>;
  style?: Maybe<Style>;
  thema?: Maybe<Thema>;
};

export type TokenOrder = {
  accessId?: Maybe<Scalars['String']>;
  address?: Maybe<Scalars['String']>;
  collectionUuid?: Maybe<Scalars['String']>;
  createdAt?: Maybe<Scalars['Date']>;
  currency?: Maybe<Currency>;
  errorCode?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  myShopCollectionImageUuid?: Maybe<Scalars['String']>;
  payType?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['Float']>;
  shopUuid?: Maybe<Scalars['String']>;
  status?: Maybe<TokenOrderStatus>;
  tokenId?: Maybe<Scalars['String']>;
  tokenUuid?: Maybe<Scalars['String']>;
  transactionId?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  uuid: Scalars['String'];
  wallet?: Maybe<Scalars['String']>;
};

export enum TokenOrderStatus {
  Created = 'created',
  NftFailed = 'nft_failed',
  NftSucceeded = 'nft_succeeded',
  Pending = 'pending',
  Processing = 'processing',
}

export type UpdateAdminInput = {
  role?: AdminRole;
  uuid: Scalars['String'];
};

export type UpdateChainNetworkInput = {
  blockExplorer?: InputMaybe<Scalars['String']>;
  chainId?: InputMaybe<Scalars['String']>;
  contractAddress?: InputMaybe<Scalars['String']>;
  icon?: InputMaybe<Scalars['String']>;
  /** File */
  iconFile?: InputMaybe<Scalars['Upload']>;
  name?: InputMaybe<Scalars['String']>;
  rpcUrl?: InputMaybe<Scalars['String']>;
  subgraphUrl?: InputMaybe<Scalars['String']>;
  testMode?: InputMaybe<Scalars['Boolean']>;
  tokenName?: InputMaybe<Scalars['String']>;
  tokenSymbol?: InputMaybe<Scalars['String']>;
  tokenSymbols?: InputMaybe<Array<Scalars['String']>>;
  uuid: Scalars['String'];
};

export type UpdateOrganizationStatusInput = {
  status?: InputMaybe<OrganizationStatus>;
  uuid: Scalars['String'];
};

export type UpdatePlanInput = {
  additionalMemberFee?: InputMaybe<Scalars['Float']>;
  additionalShopFee?: InputMaybe<Scalars['Float']>;
  additionalSiteFee?: InputMaybe<Scalars['Float']>;
  additionalUserFee?: InputMaybe<Scalars['Float']>;
  agentFeeRatio?: InputMaybe<Scalars['Float']>;
  availableForSubscribe?: InputMaybe<Scalars['Boolean']>;
  customDomain?: InputMaybe<Scalars['Boolean']>;
  displayLogo?: InputMaybe<Scalars['Boolean']>;
  initialAward?: InputMaybe<Scalars['Float']>;
  numberOfAdminUsers?: InputMaybe<Scalars['Float']>;
  numberOfMemberPerSite?: InputMaybe<Scalars['Float']>;
  numberOfMemberSites?: InputMaybe<Scalars['Float']>;
  numberOfShops?: InputMaybe<Scalars['Float']>;
  planName?: InputMaybe<Scalars['String']>;
  pvLimit?: InputMaybe<Scalars['Float']>;
  revenueShareRatio?: InputMaybe<Scalars['Float']>;
  status?: InputMaybe<PlanStatus>;
  type?: InputMaybe<PlanType>;
  uuid?: InputMaybe<Scalars['String']>;
};

export type UpdateRevenueShareInput = {
  fee: Scalars['Float'];
  from: Scalars['Float'];
  unit: RevenueShareUnit;
  uuid: Scalars['String'];
};

export type User = {
  createdAt: Scalars['Date'];
  displayName: Scalars['String'];
  email: Scalars['String'];
  language?: Maybe<Scalars['String']>;
  /** Firebase uid */
  uid: Scalars['String'];
  updatedAt?: Maybe<Scalars['Date']>;
  uuid: Scalars['String'];
};

export type UserFilterField = {
  key: UserQueryKey;
  operator: QueryOperator;
  value: Array<Scalars['String']>;
};

export enum UserQueryKey {
  CreatedAt = 'CREATED_AT',
  DisplayName = 'DISPLAY_NAME',
  Email = 'EMAIL',
  Uid = 'UID',
  Uuid = 'UUID',
}

export type AddAdminMutationVariables = Exact<{
  input: AddAdminInput;
}>;

export type AddAdminMutation = {
  addAdmin: { uuid: string; uid?: string | null; displayName?: string | null; email: string; role: AdminRole };
};

export type AddPlanMutationVariables = Exact<{
  input: PlanInput;
}>;

export type AddPlanMutation = { addPlan: { uuid: string; planName?: string | null; monthlyFee?: number | null } };

export type AddRevenueShareMutationVariables = Exact<{
  input: RevenueShareInput;
}>;

export type AddRevenueShareMutation = {
  addRevenueShare: {
    fee: number;
    uuid: string;
    from: number;
    unit: RevenueShareUnit;
    planUuid?: string | null;
    currency: Currency;
    updatedAt: any;
    createdAt: any;
  };
};

export type CreateNetworkMutationVariables = Exact<{
  input: CreateChainNetworkInput;
}>;

export type CreateNetworkMutation = {
  createNetwork: {
    uuid: string;
    chainId: string;
    name: string;
    icon?: string | null;
    rpcUrl: string;
    tokenName?: string | null;
    tokenSymbol?: string | null;
    tokenSymbols?: Array<string> | null;
    blockExplorer?: string | null;
    contractAddress?: string | null;
    walletAddress?: string | null;
    subgraphUrl?: string | null;
    testMode?: boolean | null;
    createdAt?: any | null;
  };
};

export type RemoveAdminMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemoveAdminMutation = { removeAdmin: boolean };

export type RemoveNetworkMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemoveNetworkMutation = { removeNetwork: boolean };

export type RemoveRevenueShareMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemoveRevenueShareMutation = { removeRevenueShare: boolean };

export type RemovePlanMutationVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type RemovePlanMutation = { removePlan: boolean };

export type UpdateAdminMutationVariables = Exact<{
  input: UpdateAdminInput;
}>;

export type UpdateAdminMutation = {
  updateAdmin: { uuid: string; uid?: string | null; displayName?: string | null; email: string; role: AdminRole };
};

export type UpdateNetworkMutationVariables = Exact<{
  input: UpdateChainNetworkInput;
}>;

export type UpdateNetworkMutation = {
  updateNetwork: {
    uuid: string;
    chainId: string;
    name: string;
    icon?: string | null;
    rpcUrl: string;
    tokenName?: string | null;
    tokenSymbol?: string | null;
    tokenSymbols?: Array<string> | null;
    blockExplorer?: string | null;
    contractAddress?: string | null;
    walletAddress?: string | null;
    subgraphUrl?: string | null;
    testMode?: boolean | null;
    createdAt?: any | null;
  };
};

export type UpdateOrganizationStatusMutationVariables = Exact<{
  input: UpdateOrganizationStatusInput;
}>;

export type UpdateOrganizationStatusMutation = { updateOrganizationStatus: { uuid: string } };

export type UpdatePlanMutationVariables = Exact<{
  input: UpdatePlanInput;
}>;

export type UpdatePlanMutation = { updatePlan: { uuid: string; planName?: string | null; monthlyFee?: number | null } };

export type UpdateRevenueShareMutationVariables = Exact<{
  input: UpdateRevenueShareInput;
}>;

export type UpdateRevenueShareMutation = {
  updateRevenueShare: {
    fee: number;
    uuid: string;
    from: number;
    unit: RevenueShareUnit;
    planUuid?: string | null;
    currency: Currency;
    updatedAt: any;
    createdAt: any;
  };
};

export type UpdateSystemSettingsMutationVariables = Exact<{
  input: SystemSettingInput;
}>;

export type UpdateSystemSettingsMutation = { updateSystemSettings: boolean };

export type CountAllQueryVariables = Exact<{ [key: string]: never }>;

export type CountAllQuery = {
  countAll?: {
    totalAccount: number;
    totalCollection: number;
    totalShop: number;
    totalMemberSite: number;
    totalOrganization: number;
  } | null;
};

export type GetMasterWalletAddressQueryVariables = Exact<{ [key: string]: never }>;

export type GetMasterWalletAddressQuery = { getMasterWalletAddress: string };

export type GetMeQueryVariables = Exact<{ [key: string]: never }>;

export type GetMeQuery = {
  getMe?: {
    uuid: string;
    uid?: string | null;
    displayName?: string | null;
    email: string;
    role: AdminRole;
    createdAt: any;
  } | null;
};

export type GetMyShopQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetMyShopQuery = {
  getMyShop: {
    uuid: string;
    publish?: boolean | null;
    ownerUid: string;
    createdAt: any;
    updatedAt?: any | null;
    hasUpdate?: boolean | null;
    organizationUuid: string;
    shopType?: string | null;
    nftActivationSettings?: {
      expireMethod?: string | null;
      expireDate?: any | null;
      expireValue?: string | null;
      expireUnit?: string | null;
      acquisitionInformation: Array<{
        id?: string | null;
        type?: string | null;
        displayName?: string | null;
        required?: boolean | null;
      }>;
    } | null;
    siteSetting?: {
      title?: string | null;
      description?: string | null;
      banner?: string | null;
      ratioBanner?: string | null;
      favicon?: string | null;
      ogp?: string | null;
      logo?: string | null;
      meta?: string | null;
      name?: string | null;
      category?: string | null;
      theme?: {
        showLabelSample?: boolean | null;
        positionLabelSample?: Array<number> | null;
        thema?: { template?: string | null } | null;
        style?: {
          spacing?: number | null;
          spacingSm?: number | null;
          font?: string | null;
          textColor?: string | null;
          titleColor?: string | null;
          buttonColor?: string | null;
          headerColor?: string | null;
          headerTextColor?: string | null;
          backgroundColor?: string | null;
          descriptionColor?: string | null;
          collectionTitleColor?: string | null;
          nftCardTextColor?: string | null;
          nftCardBackgroundColor?: string | null;
          collectionBorderColor?: string | null;
          collectionNftCardImageMode?: string | null;
        } | null;
        navi?: {
          pageTitle?: string | null;
          pageTitleJa?: string | null;
          description?: string | null;
          descriptionJa?: string | null;
        } | null;
      } | null;
    } | null;
    operation?: {
      type?: string | null;
      name?: string | null;
      dayOfBirth?: any | null;
      sex?: string | null;
      detailName?: {
        lastName?: string | null;
        firstName?: string | null;
        lastNameKana?: string | null;
        firstNameKana?: string | null;
        lastNameEn?: string | null;
        firstNameEn?: string | null;
      } | null;
      shopName?: { name?: string | null; nameKana?: string | null } | null;
      address?: {
        postalCode?: string | null;
        prefecture?: string | null;
        address?: string | null;
        addressDisplay?: boolean | null;
      } | null;
      contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
    } | null;
    shopInformation?: {
      sortBy?: string | null;
      policy?: string | null;
      terms?: string | null;
      orderBy?: string | null;
      information?: string | null;
    } | null;
    paymentMethod?: {
      secretKey?: string | null;
      publicKey?: string | null;
      enableCard?: boolean | null;
      baseCurrency?: Currency | null;
    } | null;
    domain?: { name?: string | null; subDomain?: string | null; customDomain?: string | null } | null;
  };
};

export type GetNetworkQueryVariables = Exact<{ [key: string]: never }>;

export type GetNetworkQuery = {
  getNetwork?: {
    uuid: string;
    chainId: string;
    name: string;
    icon?: string | null;
    rpcUrl: string;
    tokenName?: string | null;
    tokenSymbol?: string | null;
    tokenSymbols?: Array<string> | null;
    blockExplorer?: string | null;
    contractAddress?: string | null;
    walletAddress?: string | null;
    subgraphUrl?: string | null;
    testMode?: boolean | null;
    createdAt?: any | null;
  } | null;
};

export type GetOrganizationQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetOrganizationQuery = {
  getOrganization: {
    uuid: string;
    name: string;
    contactEmail: string;
    status: OrganizationStatus;
    createdAt: any;
    updatedAt?: any | null;
    totalMember: number;
    masterWalletAddress?: string | null;
    paymentSetting?: {
      requiredAcceptTerms?: boolean | null;
      termsUrl?: string | null;
      policyUrl?: string | null;
      secretKey?: string | null;
      publicKey?: string | null;
    } | null;
    currencySetting?: { baseCurrency?: Currency | null } | null;
    ipfsStorageApiKeys: Array<{ storage: string; apiKey: string }>;
    operation?: {
      type?: string | null;
      name?: string | null;
      dayOfBirth?: any | null;
      sex?: string | null;
      detailName?: {
        lastName?: string | null;
        firstName?: string | null;
        lastNameKana?: string | null;
        firstNameKana?: string | null;
        lastNameEn?: string | null;
        firstNameEn?: string | null;
      } | null;
      shopName?: { name?: string | null; nameKana?: string | null } | null;
      address?: {
        postalCode?: string | null;
        prefecture?: string | null;
        address?: string | null;
        addressDisplay?: boolean | null;
      } | null;
      contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
    } | null;
  };
};

export type GetPlanQueryVariables = Exact<{
  uuid: Scalars['String'];
}>;

export type GetPlanQuery = {
  getPlan: {
    uuid: string;
    type?: PlanType | null;
    status?: PlanStatus | null;
    pvLimit?: number | null;
    planName?: string | null;
    currency?: Currency | null;
    annualFee?: number | null;
    createdAt: any;
    updatedAt?: any | null;
    monthlyFee?: number | null;
    displayLogo?: boolean | null;
    customDomain?: boolean | null;
    initialAward?: number | null;
    agentFeeRatio?: number | null;
    numberOfShops?: number | null;
    revenueShareRatio?: number | null;
    additionalUserFee?: number | null;
    additionalShopFee?: number | null;
    additionalSiteFee?: number | null;
    numberOfAdminUsers?: number | null;
    numberOfMemberSites?: number | null;
    additionalMemberFee?: number | null;
    numberOfMemberPerSite?: number | null;
    availableForSubscribe?: boolean | null;
  };
};

export type GetPlansQueryVariables = Exact<{ [key: string]: never }>;

export type GetPlansQuery = {
  getPlans: Array<{
    uuid: string;
    type?: PlanType | null;
    status?: PlanStatus | null;
    pvLimit?: number | null;
    planName?: string | null;
    currency?: Currency | null;
    annualFee?: number | null;
    createdAt: any;
    updatedAt?: any | null;
    monthlyFee?: number | null;
    displayLogo?: boolean | null;
    customDomain?: boolean | null;
    initialAward?: number | null;
    agentFeeRatio?: number | null;
    numberOfShops?: number | null;
    revenueShareRatio?: number | null;
    additionalUserFee?: number | null;
    additionalShopFee?: number | null;
    additionalSiteFee?: number | null;
    numberOfAdminUsers?: number | null;
    numberOfMemberSites?: number | null;
    additionalMemberFee?: number | null;
    numberOfMemberPerSite?: number | null;
    availableForSubscribe?: boolean | null;
  }>;
};

export type GetRevenueShareQueryVariables = Exact<{ [key: string]: never }>;

export type GetRevenueShareQuery = {
  getRevenueShare: Array<{
    fee: number;
    uuid: string;
    from: number;
    unit: RevenueShareUnit;
    planUuid?: string | null;
    currency: Currency;
    updatedAt: any;
    createdAt: any;
  }>;
};

export type GetSystemSettingsQueryVariables = Exact<{ [key: string]: never }>;

export type GetSystemSettingsQuery = {
  getSystemSettings: { maximumPoint: number; paymentLiveMode: boolean; restrictedOrganization: boolean };
};

export type ListAdminQueryVariables = Exact<{ [key: string]: never }>;

export type ListAdminQuery = {
  listAdmin: Array<{
    uuid: string;
    uid?: string | null;
    displayName?: string | null;
    email: string;
    role: AdminRole;
    createdAt: any;
  }>;
};

export type ListMyShopCollectionsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MyShopCollectionQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where: ListMyShopCollectionsFilter;
}>;

export type ListMyShopCollectionsQuery = {
  listMyShopCollections: {
    items: Array<{
      uuid?: string | null;
      type?: string | null;
      name?: string | null;
      desc?: string | null;
      order?: number | null;
      nameJa?: string | null;
      descJa?: string | null;
      status?: string | null;
      sortBy?: string | null;
      orderBy?: string | null;
      shopUuid?: string | null;
      createdAt?: any | null;
      updatedAt?: any | null;
      collectionUuid?: string | null;
      collection?: {
        uuid: string;
        name: string;
        desc?: string | null;
        type?: string | null;
        nameJa?: string | null;
        descJa?: string | null;
        images?: Array<string> | null;
        symbol: string;
        network: string;
        ownerUid: string;
        createdAt: any;
        ownerAddress?: string | null;
        contractAddress: string;
        organizationUuid: string;
      } | null;
      collectionImages?: Array<{
        uuid: string;
        name?: string | null;
        image?: string | null;
        price?: number | null;
        order?: number | null;
        nameJa?: string | null;
        status?: string | null;
        maxMint?: number | null;
        createdAt: any;
        currentMint?: number | null;
        collectionUuid: string;
        collectionImageUuid?: string | null;
        myShopCollectionUuid?: string | null;
        myShopCollectionImageUuid?: string | null;
        myShopCollectionImageName?: string | null;
      }> | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListNetworksQueryVariables = Exact<{ [key: string]: never }>;

export type ListNetworksQuery = {
  listNetworks: Array<{
    uuid: string;
    chainId: string;
    name: string;
    icon?: string | null;
    rpcUrl: string;
    tokenName?: string | null;
    tokenSymbol?: string | null;
    tokenSymbols?: Array<string> | null;
    blockExplorer?: string | null;
    contractAddress?: string | null;
    walletAddress?: string | null;
    subgraphUrl?: string | null;
    testMode?: boolean | null;
    createdAt?: any | null;
  }>;
};

export type ListOrganizationCollectionsQueryVariables = Exact<{
  organizationUuid: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<CollectionQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListCollectionsFilter>;
}>;

export type ListOrganizationCollectionsQuery = {
  listOrganizationCollections: {
    items: Array<{
      uuid: string;
      ownerUid: string;
      network: string;
      name: string;
      symbol: string;
      organizationUuid: string;
      contractAddress: string;
      ownerAddress?: string | null;
      createdAt: any;
      desc?: string | null;
      descJa?: string | null;
      type?: string | null;
      images?: Array<string> | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListOrganizationShopsQueryVariables = Exact<{
  organizationUuid: Scalars['String'];
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<MyShopQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListMyShopsFilter>;
}>;

export type ListOrganizationShopsQuery = {
  listOrganizationShops: {
    items: Array<{
      uuid: string;
      ownerUid: string;
      createdAt: any;
      updatedAt?: any | null;
      publish?: boolean | null;
      shopType?: string | null;
      organizationUuid: string;
      siteSetting?: {
        title?: string | null;
        description?: string | null;
        banner?: string | null;
        favicon?: string | null;
        ogp?: string | null;
        category?: string | null;
        theme?: {
          thema?: { template?: string | null } | null;
          style?: {
            font?: string | null;
            textColor?: string | null;
            backgroundColor?: string | null;
            buttonColor?: string | null;
          } | null;
          navi?: { pageTitle?: string | null; description?: string | null } | null;
        } | null;
      } | null;
      operation?: {
        type?: string | null;
        name?: string | null;
        dayOfBirth?: any | null;
        sex?: string | null;
        detailName?: {
          lastName?: string | null;
          firstName?: string | null;
          lastNameKana?: string | null;
          firstNameKana?: string | null;
          lastNameEn?: string | null;
          firstNameEn?: string | null;
        } | null;
        shopName?: { name?: string | null; nameKana?: string | null } | null;
        address?: {
          postalCode?: string | null;
          prefecture?: string | null;
          address?: string | null;
          addressDisplay?: boolean | null;
        } | null;
        contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
      } | null;
      shopInformation?: { information?: string | null } | null;
      paymentMethod?: {
        secretKey?: string | null;
        publicKey?: string | null;
        enableCard?: boolean | null;
        baseCurrency?: Currency | null;
      } | null;
      domain?: { name?: string | null; subDomain?: string | null; customDomain?: string | null } | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListOrganizationsQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<OrganizationQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListOrganizationsFilter>;
}>;

export type ListOrganizationsQuery = {
  listOrganizations: {
    items: Array<{
      uuid: string;
      name: string;
      status: OrganizationStatus;
      contactEmail: string;
      createdAt: any;
      updatedAt?: any | null;
      masterWalletAddress?: string | null;
      ipfsStorageApiKeys: Array<{ storage: string; apiKey: string }>;
      operation?: {
        type?: string | null;
        name?: string | null;
        dayOfBirth?: any | null;
        sex?: string | null;
        detailName?: {
          lastName?: string | null;
          firstName?: string | null;
          lastNameKana?: string | null;
          firstNameKana?: string | null;
          lastNameEn?: string | null;
          firstNameEn?: string | null;
        } | null;
        shopName?: { name?: string | null; nameKana?: string | null } | null;
        address?: {
          postalCode?: string | null;
          prefecture?: string | null;
          address?: string | null;
          addressDisplay?: boolean | null;
        } | null;
        contact?: { phoneNumber?: string | null; publish?: boolean | null } | null;
      } | null;
      paymentSetting?: {
        secretKey?: string | null;
        publicKey?: string | null;
        requiredAcceptTerms?: boolean | null;
        policyUrl?: string | null;
        termsUrl?: string | null;
      } | null;
      currencySetting?: { baseCurrency?: Currency | null } | null;
    }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export type ListUsersQueryVariables = Exact<{
  page?: InputMaybe<Scalars['Int']>;
  limit?: InputMaybe<Scalars['Int']>;
  sortBy?: InputMaybe<UserQueryKey>;
  orderBy?: InputMaybe<OrderBy>;
  searchText?: InputMaybe<Scalars['String']>;
  where?: InputMaybe<ListUsersFilter>;
}>;

export type ListUsersQuery = {
  listUsers: {
    items: Array<{ uuid: string; uid: string; displayName: string; email: string; createdAt: any }>;
    pagination: { totalItems: number; itemsPerPage: number; totalPages: number; currentPage: number };
  };
};

export const AddAdminDocument = gql`
  mutation addAdmin($input: AddAdminInput!) {
    addAdmin(input: $input) {
      uuid
      uid
      displayName
      email
      role
    }
  }
`;
export type AddAdminMutationFn = Apollo.MutationFunction<AddAdminMutation, AddAdminMutationVariables>;

/**
 * __useAddAdminMutation__
 *
 * To run a mutation, you first call `useAddAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addAdminMutation, { data, loading, error }] = useAddAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<AddAdminMutation, AddAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddAdminMutation, AddAdminMutationVariables>(AddAdminDocument, options);
}
export type AddAdminMutationHookResult = ReturnType<typeof useAddAdminMutation>;
export type AddAdminMutationResult = Apollo.MutationResult<AddAdminMutation>;
export type AddAdminMutationOptions = Apollo.BaseMutationOptions<AddAdminMutation, AddAdminMutationVariables>;
export const AddPlanDocument = gql`
  mutation addPlan($input: PlanInput!) {
    addPlan(input: $input) {
      uuid
      planName
      monthlyFee
    }
  }
`;
export type AddPlanMutationFn = Apollo.MutationFunction<AddPlanMutation, AddPlanMutationVariables>;

/**
 * __useAddPlanMutation__
 *
 * To run a mutation, you first call `useAddPlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddPlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addPlanMutation, { data, loading, error }] = useAddPlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddPlanMutation(
  baseOptions?: Apollo.MutationHookOptions<AddPlanMutation, AddPlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddPlanMutation, AddPlanMutationVariables>(AddPlanDocument, options);
}
export type AddPlanMutationHookResult = ReturnType<typeof useAddPlanMutation>;
export type AddPlanMutationResult = Apollo.MutationResult<AddPlanMutation>;
export type AddPlanMutationOptions = Apollo.BaseMutationOptions<AddPlanMutation, AddPlanMutationVariables>;
export const AddRevenueShareDocument = gql`
  mutation addRevenueShare($input: RevenueShareInput!) {
    addRevenueShare(input: $input) {
      fee
      uuid
      from
      unit
      planUuid
      currency
      updatedAt
      createdAt
    }
  }
`;
export type AddRevenueShareMutationFn = Apollo.MutationFunction<
  AddRevenueShareMutation,
  AddRevenueShareMutationVariables
>;

/**
 * __useAddRevenueShareMutation__
 *
 * To run a mutation, you first call `useAddRevenueShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useAddRevenueShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [addRevenueShareMutation, { data, loading, error }] = useAddRevenueShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useAddRevenueShareMutation(
  baseOptions?: Apollo.MutationHookOptions<AddRevenueShareMutation, AddRevenueShareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<AddRevenueShareMutation, AddRevenueShareMutationVariables>(
    AddRevenueShareDocument,
    options
  );
}
export type AddRevenueShareMutationHookResult = ReturnType<typeof useAddRevenueShareMutation>;
export type AddRevenueShareMutationResult = Apollo.MutationResult<AddRevenueShareMutation>;
export type AddRevenueShareMutationOptions = Apollo.BaseMutationOptions<
  AddRevenueShareMutation,
  AddRevenueShareMutationVariables
>;
export const CreateNetworkDocument = gql`
  mutation createNetwork($input: CreateChainNetworkInput!) {
    createNetwork(input: $input) {
      uuid
      chainId
      name
      icon
      rpcUrl
      tokenName
      tokenSymbol
      tokenSymbols
      blockExplorer
      contractAddress
      walletAddress
      subgraphUrl
      testMode
      createdAt
    }
  }
`;
export type CreateNetworkMutationFn = Apollo.MutationFunction<CreateNetworkMutation, CreateNetworkMutationVariables>;

/**
 * __useCreateNetworkMutation__
 *
 * To run a mutation, you first call `useCreateNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useCreateNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [createNetworkMutation, { data, loading, error }] = useCreateNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useCreateNetworkMutation(
  baseOptions?: Apollo.MutationHookOptions<CreateNetworkMutation, CreateNetworkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<CreateNetworkMutation, CreateNetworkMutationVariables>(CreateNetworkDocument, options);
}
export type CreateNetworkMutationHookResult = ReturnType<typeof useCreateNetworkMutation>;
export type CreateNetworkMutationResult = Apollo.MutationResult<CreateNetworkMutation>;
export type CreateNetworkMutationOptions = Apollo.BaseMutationOptions<
  CreateNetworkMutation,
  CreateNetworkMutationVariables
>;
export const RemoveAdminDocument = gql`
  mutation removeAdmin($uuid: String!) {
    removeAdmin(uuid: $uuid)
  }
`;
export type RemoveAdminMutationFn = Apollo.MutationFunction<RemoveAdminMutation, RemoveAdminMutationVariables>;

/**
 * __useRemoveAdminMutation__
 *
 * To run a mutation, you first call `useRemoveAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeAdminMutation, { data, loading, error }] = useRemoveAdminMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveAdminMutation, RemoveAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveAdminMutation, RemoveAdminMutationVariables>(RemoveAdminDocument, options);
}
export type RemoveAdminMutationHookResult = ReturnType<typeof useRemoveAdminMutation>;
export type RemoveAdminMutationResult = Apollo.MutationResult<RemoveAdminMutation>;
export type RemoveAdminMutationOptions = Apollo.BaseMutationOptions<RemoveAdminMutation, RemoveAdminMutationVariables>;
export const RemoveNetworkDocument = gql`
  mutation removeNetwork($uuid: String!) {
    removeNetwork(uuid: $uuid)
  }
`;
export type RemoveNetworkMutationFn = Apollo.MutationFunction<RemoveNetworkMutation, RemoveNetworkMutationVariables>;

/**
 * __useRemoveNetworkMutation__
 *
 * To run a mutation, you first call `useRemoveNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeNetworkMutation, { data, loading, error }] = useRemoveNetworkMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveNetworkMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveNetworkMutation, RemoveNetworkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveNetworkMutation, RemoveNetworkMutationVariables>(RemoveNetworkDocument, options);
}
export type RemoveNetworkMutationHookResult = ReturnType<typeof useRemoveNetworkMutation>;
export type RemoveNetworkMutationResult = Apollo.MutationResult<RemoveNetworkMutation>;
export type RemoveNetworkMutationOptions = Apollo.BaseMutationOptions<
  RemoveNetworkMutation,
  RemoveNetworkMutationVariables
>;
export const RemoveRevenueShareDocument = gql`
  mutation removeRevenueShare($uuid: String!) {
    removeRevenueShare(uuid: $uuid)
  }
`;
export type RemoveRevenueShareMutationFn = Apollo.MutationFunction<
  RemoveRevenueShareMutation,
  RemoveRevenueShareMutationVariables
>;

/**
 * __useRemoveRevenueShareMutation__
 *
 * To run a mutation, you first call `useRemoveRevenueShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemoveRevenueShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removeRevenueShareMutation, { data, loading, error }] = useRemoveRevenueShareMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemoveRevenueShareMutation(
  baseOptions?: Apollo.MutationHookOptions<RemoveRevenueShareMutation, RemoveRevenueShareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemoveRevenueShareMutation, RemoveRevenueShareMutationVariables>(
    RemoveRevenueShareDocument,
    options
  );
}
export type RemoveRevenueShareMutationHookResult = ReturnType<typeof useRemoveRevenueShareMutation>;
export type RemoveRevenueShareMutationResult = Apollo.MutationResult<RemoveRevenueShareMutation>;
export type RemoveRevenueShareMutationOptions = Apollo.BaseMutationOptions<
  RemoveRevenueShareMutation,
  RemoveRevenueShareMutationVariables
>;
export const RemovePlanDocument = gql`
  mutation removePlan($uuid: String!) {
    removePlan(uuid: $uuid)
  }
`;
export type RemovePlanMutationFn = Apollo.MutationFunction<RemovePlanMutation, RemovePlanMutationVariables>;

/**
 * __useRemovePlanMutation__
 *
 * To run a mutation, you first call `useRemovePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useRemovePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [removePlanMutation, { data, loading, error }] = useRemovePlanMutation({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useRemovePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<RemovePlanMutation, RemovePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<RemovePlanMutation, RemovePlanMutationVariables>(RemovePlanDocument, options);
}
export type RemovePlanMutationHookResult = ReturnType<typeof useRemovePlanMutation>;
export type RemovePlanMutationResult = Apollo.MutationResult<RemovePlanMutation>;
export type RemovePlanMutationOptions = Apollo.BaseMutationOptions<RemovePlanMutation, RemovePlanMutationVariables>;
export const UpdateAdminDocument = gql`
  mutation updateAdmin($input: UpdateAdminInput!) {
    updateAdmin(input: $input) {
      uuid
      uid
      displayName
      email
      role
    }
  }
`;
export type UpdateAdminMutationFn = Apollo.MutationFunction<UpdateAdminMutation, UpdateAdminMutationVariables>;

/**
 * __useUpdateAdminMutation__
 *
 * To run a mutation, you first call `useUpdateAdminMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateAdminMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateAdminMutation, { data, loading, error }] = useUpdateAdminMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateAdminMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateAdminMutation, UpdateAdminMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateAdminMutation, UpdateAdminMutationVariables>(UpdateAdminDocument, options);
}
export type UpdateAdminMutationHookResult = ReturnType<typeof useUpdateAdminMutation>;
export type UpdateAdminMutationResult = Apollo.MutationResult<UpdateAdminMutation>;
export type UpdateAdminMutationOptions = Apollo.BaseMutationOptions<UpdateAdminMutation, UpdateAdminMutationVariables>;
export const UpdateNetworkDocument = gql`
  mutation updateNetwork($input: UpdateChainNetworkInput!) {
    updateNetwork(input: $input) {
      uuid
      chainId
      name
      icon
      rpcUrl
      tokenName
      tokenSymbol
      tokenSymbols
      blockExplorer
      contractAddress
      walletAddress
      subgraphUrl
      testMode
      createdAt
    }
  }
`;
export type UpdateNetworkMutationFn = Apollo.MutationFunction<UpdateNetworkMutation, UpdateNetworkMutationVariables>;

/**
 * __useUpdateNetworkMutation__
 *
 * To run a mutation, you first call `useUpdateNetworkMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateNetworkMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateNetworkMutation, { data, loading, error }] = useUpdateNetworkMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateNetworkMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateNetworkMutation, UpdateNetworkMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateNetworkMutation, UpdateNetworkMutationVariables>(UpdateNetworkDocument, options);
}
export type UpdateNetworkMutationHookResult = ReturnType<typeof useUpdateNetworkMutation>;
export type UpdateNetworkMutationResult = Apollo.MutationResult<UpdateNetworkMutation>;
export type UpdateNetworkMutationOptions = Apollo.BaseMutationOptions<
  UpdateNetworkMutation,
  UpdateNetworkMutationVariables
>;
export const UpdateOrganizationStatusDocument = gql`
  mutation updateOrganizationStatus($input: UpdateOrganizationStatusInput!) {
    updateOrganizationStatus(input: $input) {
      uuid
    }
  }
`;
export type UpdateOrganizationStatusMutationFn = Apollo.MutationFunction<
  UpdateOrganizationStatusMutation,
  UpdateOrganizationStatusMutationVariables
>;

/**
 * __useUpdateOrganizationStatusMutation__
 *
 * To run a mutation, you first call `useUpdateOrganizationStatusMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateOrganizationStatusMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateOrganizationStatusMutation, { data, loading, error }] = useUpdateOrganizationStatusMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateOrganizationStatusMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateOrganizationStatusMutation, UpdateOrganizationStatusMutationVariables>(
    UpdateOrganizationStatusDocument,
    options
  );
}
export type UpdateOrganizationStatusMutationHookResult = ReturnType<typeof useUpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationResult = Apollo.MutationResult<UpdateOrganizationStatusMutation>;
export type UpdateOrganizationStatusMutationOptions = Apollo.BaseMutationOptions<
  UpdateOrganizationStatusMutation,
  UpdateOrganizationStatusMutationVariables
>;
export const UpdatePlanDocument = gql`
  mutation updatePlan($input: UpdatePlanInput!) {
    updatePlan(input: $input) {
      uuid
      planName
      monthlyFee
    }
  }
`;
export type UpdatePlanMutationFn = Apollo.MutationFunction<UpdatePlanMutation, UpdatePlanMutationVariables>;

/**
 * __useUpdatePlanMutation__
 *
 * To run a mutation, you first call `useUpdatePlanMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdatePlanMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updatePlanMutation, { data, loading, error }] = useUpdatePlanMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdatePlanMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdatePlanMutation, UpdatePlanMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdatePlanMutation, UpdatePlanMutationVariables>(UpdatePlanDocument, options);
}
export type UpdatePlanMutationHookResult = ReturnType<typeof useUpdatePlanMutation>;
export type UpdatePlanMutationResult = Apollo.MutationResult<UpdatePlanMutation>;
export type UpdatePlanMutationOptions = Apollo.BaseMutationOptions<UpdatePlanMutation, UpdatePlanMutationVariables>;
export const UpdateRevenueShareDocument = gql`
  mutation updateRevenueShare($input: UpdateRevenueShareInput!) {
    updateRevenueShare(input: $input) {
      fee
      uuid
      from
      unit
      planUuid
      currency
      updatedAt
      createdAt
    }
  }
`;
export type UpdateRevenueShareMutationFn = Apollo.MutationFunction<
  UpdateRevenueShareMutation,
  UpdateRevenueShareMutationVariables
>;

/**
 * __useUpdateRevenueShareMutation__
 *
 * To run a mutation, you first call `useUpdateRevenueShareMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateRevenueShareMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateRevenueShareMutation, { data, loading, error }] = useUpdateRevenueShareMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateRevenueShareMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateRevenueShareMutation, UpdateRevenueShareMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateRevenueShareMutation, UpdateRevenueShareMutationVariables>(
    UpdateRevenueShareDocument,
    options
  );
}
export type UpdateRevenueShareMutationHookResult = ReturnType<typeof useUpdateRevenueShareMutation>;
export type UpdateRevenueShareMutationResult = Apollo.MutationResult<UpdateRevenueShareMutation>;
export type UpdateRevenueShareMutationOptions = Apollo.BaseMutationOptions<
  UpdateRevenueShareMutation,
  UpdateRevenueShareMutationVariables
>;
export const UpdateSystemSettingsDocument = gql`
  mutation updateSystemSettings($input: SystemSettingInput!) {
    updateSystemSettings(input: $input)
  }
`;
export type UpdateSystemSettingsMutationFn = Apollo.MutationFunction<
  UpdateSystemSettingsMutation,
  UpdateSystemSettingsMutationVariables
>;

/**
 * __useUpdateSystemSettingsMutation__
 *
 * To run a mutation, you first call `useUpdateSystemSettingsMutation` within a React component and pass it any options that fit your needs.
 * When your component renders, `useUpdateSystemSettingsMutation` returns a tuple that includes:
 * - A mutate function that you can call at any time to execute the mutation
 * - An object with fields that represent the current status of the mutation's execution
 *
 * @param baseOptions options that will be passed into the mutation, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options-2;
 *
 * @example
 * const [updateSystemSettingsMutation, { data, loading, error }] = useUpdateSystemSettingsMutation({
 *   variables: {
 *      input: // value for 'input'
 *   },
 * });
 */
export function useUpdateSystemSettingsMutation(
  baseOptions?: Apollo.MutationHookOptions<UpdateSystemSettingsMutation, UpdateSystemSettingsMutationVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useMutation<UpdateSystemSettingsMutation, UpdateSystemSettingsMutationVariables>(
    UpdateSystemSettingsDocument,
    options
  );
}
export type UpdateSystemSettingsMutationHookResult = ReturnType<typeof useUpdateSystemSettingsMutation>;
export type UpdateSystemSettingsMutationResult = Apollo.MutationResult<UpdateSystemSettingsMutation>;
export type UpdateSystemSettingsMutationOptions = Apollo.BaseMutationOptions<
  UpdateSystemSettingsMutation,
  UpdateSystemSettingsMutationVariables
>;
export const CountAllDocument = gql`
  query countAll {
    countAll {
      totalAccount
      totalCollection
      totalShop
      totalMemberSite
      totalOrganization
    }
  }
`;

/**
 * __useCountAllQuery__
 *
 * To run a query within a React component, call `useCountAllQuery` and pass it any options that fit your needs.
 * When your component renders, `useCountAllQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useCountAllQuery({
 *   variables: {
 *   },
 * });
 */
export function useCountAllQuery(baseOptions?: Apollo.QueryHookOptions<CountAllQuery, CountAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<CountAllQuery, CountAllQueryVariables>(CountAllDocument, options);
}
export function useCountAllLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<CountAllQuery, CountAllQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<CountAllQuery, CountAllQueryVariables>(CountAllDocument, options);
}
export type CountAllQueryHookResult = ReturnType<typeof useCountAllQuery>;
export type CountAllLazyQueryHookResult = ReturnType<typeof useCountAllLazyQuery>;
export type CountAllQueryResult = Apollo.QueryResult<CountAllQuery, CountAllQueryVariables>;
export const GetMasterWalletAddressDocument = gql`
  query getMasterWalletAddress {
    getMasterWalletAddress
  }
`;

/**
 * __useGetMasterWalletAddressQuery__
 *
 * To run a query within a React component, call `useGetMasterWalletAddressQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMasterWalletAddressQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMasterWalletAddressQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMasterWalletAddressQuery(
  baseOptions?: Apollo.QueryHookOptions<GetMasterWalletAddressQuery, GetMasterWalletAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMasterWalletAddressQuery, GetMasterWalletAddressQueryVariables>(
    GetMasterWalletAddressDocument,
    options
  );
}
export function useGetMasterWalletAddressLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMasterWalletAddressQuery, GetMasterWalletAddressQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMasterWalletAddressQuery, GetMasterWalletAddressQueryVariables>(
    GetMasterWalletAddressDocument,
    options
  );
}
export type GetMasterWalletAddressQueryHookResult = ReturnType<typeof useGetMasterWalletAddressQuery>;
export type GetMasterWalletAddressLazyQueryHookResult = ReturnType<typeof useGetMasterWalletAddressLazyQuery>;
export type GetMasterWalletAddressQueryResult = Apollo.QueryResult<
  GetMasterWalletAddressQuery,
  GetMasterWalletAddressQueryVariables
>;
export const GetMeDocument = gql`
  query getMe {
    getMe {
      uuid
      uid
      displayName
      email
      role
      createdAt
    }
  }
`;

/**
 * __useGetMeQuery__
 *
 * To run a query within a React component, call `useGetMeQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMeQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMeQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetMeQuery(baseOptions?: Apollo.QueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export function useGetMeLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetMeQuery, GetMeQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMeQuery, GetMeQueryVariables>(GetMeDocument, options);
}
export type GetMeQueryHookResult = ReturnType<typeof useGetMeQuery>;
export type GetMeLazyQueryHookResult = ReturnType<typeof useGetMeLazyQuery>;
export type GetMeQueryResult = Apollo.QueryResult<GetMeQuery, GetMeQueryVariables>;
export const GetMyShopDocument = gql`
  query getMyShop($uuid: String!) {
    getMyShop(uuid: $uuid) {
      uuid
      publish
      ownerUid
      createdAt
      updatedAt
      hasUpdate
      organizationUuid
      nftActivationSettings {
        acquisitionInformation {
          id
          type
          displayName
          required
        }
        expireMethod
        expireDate
        expireValue
        expireUnit
      }
      shopType
      siteSetting {
        title
        description
        banner
        ratioBanner
        favicon
        ogp
        logo
        meta
        name
        category
        theme {
          thema {
            template
          }
          style {
            spacing
            spacingSm
            font
            textColor
            titleColor
            buttonColor
            headerColor
            headerTextColor
            backgroundColor
            descriptionColor
            collectionTitleColor
            nftCardTextColor
            nftCardBackgroundColor
            collectionBorderColor
            collectionNftCardImageMode
          }
          navi {
            pageTitle
            pageTitleJa
            description
            descriptionJa
          }
          showLabelSample
          positionLabelSample
        }
      }
      operation {
        type
        name
        detailName {
          lastName
          firstName
          lastNameKana
          firstNameKana
          lastNameEn
          firstNameEn
        }
        shopName {
          name
          nameKana
        }
        address {
          postalCode
          prefecture
          address
          addressDisplay
        }
        contact {
          phoneNumber
          publish
        }
        dayOfBirth
        sex
      }
      shopInformation {
        sortBy
        policy
        terms
        orderBy
        information
      }
      paymentMethod {
        secretKey
        publicKey
        enableCard
        baseCurrency
      }
      domain {
        name
        subDomain
        customDomain
      }
    }
  }
`;

/**
 * __useGetMyShopQuery__
 *
 * To run a query within a React component, call `useGetMyShopQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetMyShopQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetMyShopQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetMyShopQuery(baseOptions: Apollo.QueryHookOptions<GetMyShopQuery, GetMyShopQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetMyShopQuery, GetMyShopQueryVariables>(GetMyShopDocument, options);
}
export function useGetMyShopLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetMyShopQuery, GetMyShopQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetMyShopQuery, GetMyShopQueryVariables>(GetMyShopDocument, options);
}
export type GetMyShopQueryHookResult = ReturnType<typeof useGetMyShopQuery>;
export type GetMyShopLazyQueryHookResult = ReturnType<typeof useGetMyShopLazyQuery>;
export type GetMyShopQueryResult = Apollo.QueryResult<GetMyShopQuery, GetMyShopQueryVariables>;
export const GetNetworkDocument = gql`
  query getNetwork {
    getNetwork {
      uuid
      chainId
      name
      icon
      rpcUrl
      tokenName
      tokenSymbol
      tokenSymbols
      blockExplorer
      contractAddress
      walletAddress
      subgraphUrl
      testMode
      createdAt
    }
  }
`;

/**
 * __useGetNetworkQuery__
 *
 * To run a query within a React component, call `useGetNetworkQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetNetworkQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetNetworkQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetNetworkQuery(baseOptions?: Apollo.QueryHookOptions<GetNetworkQuery, GetNetworkQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetNetworkQuery, GetNetworkQueryVariables>(GetNetworkDocument, options);
}
export function useGetNetworkLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetNetworkQuery, GetNetworkQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetNetworkQuery, GetNetworkQueryVariables>(GetNetworkDocument, options);
}
export type GetNetworkQueryHookResult = ReturnType<typeof useGetNetworkQuery>;
export type GetNetworkLazyQueryHookResult = ReturnType<typeof useGetNetworkLazyQuery>;
export type GetNetworkQueryResult = Apollo.QueryResult<GetNetworkQuery, GetNetworkQueryVariables>;
export const GetOrganizationDocument = gql`
  query getOrganization($uuid: String!) {
    getOrganization(uuid: $uuid) {
      uuid
      name
      contactEmail
      status
      createdAt
      updatedAt
      totalMember
      masterWalletAddress
      paymentSetting {
        requiredAcceptTerms
        termsUrl
        policyUrl
        secretKey
        publicKey
      }
      currencySetting {
        baseCurrency
      }
      ipfsStorageApiKeys {
        storage
        apiKey
      }
      operation {
        type
        name
        detailName {
          lastName
          firstName
          lastNameKana
          firstNameKana
          lastNameEn
          firstNameEn
        }
        shopName {
          name
          nameKana
        }
        address {
          postalCode
          prefecture
          address
          addressDisplay
        }
        contact {
          phoneNumber
          publish
        }
        dayOfBirth
        sex
      }
    }
  }
`;

/**
 * __useGetOrganizationQuery__
 *
 * To run a query within a React component, call `useGetOrganizationQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetOrganizationQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetOrganizationQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetOrganizationQuery(
  baseOptions: Apollo.QueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
}
export function useGetOrganizationLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetOrganizationQuery, GetOrganizationQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetOrganizationQuery, GetOrganizationQueryVariables>(GetOrganizationDocument, options);
}
export type GetOrganizationQueryHookResult = ReturnType<typeof useGetOrganizationQuery>;
export type GetOrganizationLazyQueryHookResult = ReturnType<typeof useGetOrganizationLazyQuery>;
export type GetOrganizationQueryResult = Apollo.QueryResult<GetOrganizationQuery, GetOrganizationQueryVariables>;
export const GetPlanDocument = gql`
  query getPlan($uuid: String!) {
    getPlan(uuid: $uuid) {
      uuid
      type
      status
      pvLimit
      planName
      currency
      annualFee
      createdAt
      updatedAt
      monthlyFee
      displayLogo
      customDomain
      initialAward
      agentFeeRatio
      numberOfShops
      revenueShareRatio
      additionalUserFee
      additionalShopFee
      additionalSiteFee
      numberOfAdminUsers
      numberOfMemberSites
      additionalMemberFee
      numberOfMemberPerSite
      availableForSubscribe
    }
  }
`;

/**
 * __useGetPlanQuery__
 *
 * To run a query within a React component, call `useGetPlanQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlanQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlanQuery({
 *   variables: {
 *      uuid: // value for 'uuid'
 *   },
 * });
 */
export function useGetPlanQuery(baseOptions: Apollo.QueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
}
export function useGetPlanLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlanQuery, GetPlanQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlanQuery, GetPlanQueryVariables>(GetPlanDocument, options);
}
export type GetPlanQueryHookResult = ReturnType<typeof useGetPlanQuery>;
export type GetPlanLazyQueryHookResult = ReturnType<typeof useGetPlanLazyQuery>;
export type GetPlanQueryResult = Apollo.QueryResult<GetPlanQuery, GetPlanQueryVariables>;
export const GetPlansDocument = gql`
  query getPlans {
    getPlans {
      uuid
      type
      status
      pvLimit
      planName
      currency
      annualFee
      createdAt
      updatedAt
      monthlyFee
      displayLogo
      customDomain
      initialAward
      agentFeeRatio
      numberOfShops
      revenueShareRatio
      additionalUserFee
      additionalShopFee
      additionalSiteFee
      numberOfAdminUsers
      numberOfMemberSites
      additionalMemberFee
      numberOfMemberPerSite
      availableForSubscribe
    }
  }
`;

/**
 * __useGetPlansQuery__
 *
 * To run a query within a React component, call `useGetPlansQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetPlansQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetPlansQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetPlansQuery(baseOptions?: Apollo.QueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
}
export function useGetPlansLazyQuery(baseOptions?: Apollo.LazyQueryHookOptions<GetPlansQuery, GetPlansQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetPlansQuery, GetPlansQueryVariables>(GetPlansDocument, options);
}
export type GetPlansQueryHookResult = ReturnType<typeof useGetPlansQuery>;
export type GetPlansLazyQueryHookResult = ReturnType<typeof useGetPlansLazyQuery>;
export type GetPlansQueryResult = Apollo.QueryResult<GetPlansQuery, GetPlansQueryVariables>;
export const GetRevenueShareDocument = gql`
  query getRevenueShare {
    getRevenueShare {
      fee
      uuid
      from
      unit
      planUuid
      currency
      updatedAt
      createdAt
    }
  }
`;

/**
 * __useGetRevenueShareQuery__
 *
 * To run a query within a React component, call `useGetRevenueShareQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetRevenueShareQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetRevenueShareQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetRevenueShareQuery(
  baseOptions?: Apollo.QueryHookOptions<GetRevenueShareQuery, GetRevenueShareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetRevenueShareQuery, GetRevenueShareQueryVariables>(GetRevenueShareDocument, options);
}
export function useGetRevenueShareLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetRevenueShareQuery, GetRevenueShareQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetRevenueShareQuery, GetRevenueShareQueryVariables>(GetRevenueShareDocument, options);
}
export type GetRevenueShareQueryHookResult = ReturnType<typeof useGetRevenueShareQuery>;
export type GetRevenueShareLazyQueryHookResult = ReturnType<typeof useGetRevenueShareLazyQuery>;
export type GetRevenueShareQueryResult = Apollo.QueryResult<GetRevenueShareQuery, GetRevenueShareQueryVariables>;
export const GetSystemSettingsDocument = gql`
  query getSystemSettings {
    getSystemSettings {
      maximumPoint
      paymentLiveMode
      restrictedOrganization
    }
  }
`;

/**
 * __useGetSystemSettingsQuery__
 *
 * To run a query within a React component, call `useGetSystemSettingsQuery` and pass it any options that fit your needs.
 * When your component renders, `useGetSystemSettingsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useGetSystemSettingsQuery({
 *   variables: {
 *   },
 * });
 */
export function useGetSystemSettingsQuery(
  baseOptions?: Apollo.QueryHookOptions<GetSystemSettingsQuery, GetSystemSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<GetSystemSettingsQuery, GetSystemSettingsQueryVariables>(GetSystemSettingsDocument, options);
}
export function useGetSystemSettingsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<GetSystemSettingsQuery, GetSystemSettingsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<GetSystemSettingsQuery, GetSystemSettingsQueryVariables>(
    GetSystemSettingsDocument,
    options
  );
}
export type GetSystemSettingsQueryHookResult = ReturnType<typeof useGetSystemSettingsQuery>;
export type GetSystemSettingsLazyQueryHookResult = ReturnType<typeof useGetSystemSettingsLazyQuery>;
export type GetSystemSettingsQueryResult = Apollo.QueryResult<GetSystemSettingsQuery, GetSystemSettingsQueryVariables>;
export const ListAdminDocument = gql`
  query listAdmin {
    listAdmin {
      uuid
      uid
      displayName
      email
      role
      createdAt
    }
  }
`;

/**
 * __useListAdminQuery__
 *
 * To run a query within a React component, call `useListAdminQuery` and pass it any options that fit your needs.
 * When your component renders, `useListAdminQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListAdminQuery({
 *   variables: {
 *   },
 * });
 */
export function useListAdminQuery(baseOptions?: Apollo.QueryHookOptions<ListAdminQuery, ListAdminQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListAdminQuery, ListAdminQueryVariables>(ListAdminDocument, options);
}
export function useListAdminLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListAdminQuery, ListAdminQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListAdminQuery, ListAdminQueryVariables>(ListAdminDocument, options);
}
export type ListAdminQueryHookResult = ReturnType<typeof useListAdminQuery>;
export type ListAdminLazyQueryHookResult = ReturnType<typeof useListAdminLazyQuery>;
export type ListAdminQueryResult = Apollo.QueryResult<ListAdminQuery, ListAdminQueryVariables>;
export const ListMyShopCollectionsDocument = gql`
  query listMyShopCollections(
    $page: Int
    $limit: Int
    $sortBy: MyShopCollectionQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMyShopCollectionsFilter!
  ) {
    listMyShopCollections(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        type
        name
        desc
        order
        nameJa
        descJa
        status
        sortBy
        orderBy
        shopUuid
        createdAt
        updatedAt
        collectionUuid
        collection {
          uuid
          name
          desc
          type
          nameJa
          descJa
          images
          symbol
          network
          ownerUid
          createdAt
          ownerAddress
          contractAddress
          organizationUuid
        }
        collectionImages {
          uuid
          name
          image
          price
          order
          nameJa
          status
          maxMint
          createdAt
          currentMint
          collectionUuid
          collectionImageUuid
          myShopCollectionUuid
          myShopCollectionImageUuid
          myShopCollectionImageName
        }
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListMyShopCollectionsQuery__
 *
 * To run a query within a React component, call `useListMyShopCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListMyShopCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListMyShopCollectionsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListMyShopCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>(
    ListMyShopCollectionsDocument,
    options
  );
}
export function useListMyShopCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListMyShopCollectionsQuery, ListMyShopCollectionsQueryVariables>(
    ListMyShopCollectionsDocument,
    options
  );
}
export type ListMyShopCollectionsQueryHookResult = ReturnType<typeof useListMyShopCollectionsQuery>;
export type ListMyShopCollectionsLazyQueryHookResult = ReturnType<typeof useListMyShopCollectionsLazyQuery>;
export type ListMyShopCollectionsQueryResult = Apollo.QueryResult<
  ListMyShopCollectionsQuery,
  ListMyShopCollectionsQueryVariables
>;
export const ListNetworksDocument = gql`
  query listNetworks {
    listNetworks {
      uuid
      chainId
      name
      icon
      rpcUrl
      tokenName
      tokenSymbol
      tokenSymbols
      blockExplorer
      contractAddress
      walletAddress
      subgraphUrl
      testMode
      createdAt
    }
  }
`;

/**
 * __useListNetworksQuery__
 *
 * To run a query within a React component, call `useListNetworksQuery` and pass it any options that fit your needs.
 * When your component renders, `useListNetworksQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListNetworksQuery({
 *   variables: {
 *   },
 * });
 */
export function useListNetworksQuery(
  baseOptions?: Apollo.QueryHookOptions<ListNetworksQuery, ListNetworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListNetworksQuery, ListNetworksQueryVariables>(ListNetworksDocument, options);
}
export function useListNetworksLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListNetworksQuery, ListNetworksQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListNetworksQuery, ListNetworksQueryVariables>(ListNetworksDocument, options);
}
export type ListNetworksQueryHookResult = ReturnType<typeof useListNetworksQuery>;
export type ListNetworksLazyQueryHookResult = ReturnType<typeof useListNetworksLazyQuery>;
export type ListNetworksQueryResult = Apollo.QueryResult<ListNetworksQuery, ListNetworksQueryVariables>;
export const ListOrganizationCollectionsDocument = gql`
  query listOrganizationCollections(
    $organizationUuid: String!
    $page: Int
    $limit: Int
    $sortBy: CollectionQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListCollectionsFilter
  ) {
    listOrganizationCollections(
      organizationUuid: $organizationUuid
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        ownerUid
        network
        name
        symbol
        organizationUuid
        contractAddress
        ownerAddress
        createdAt
        desc
        descJa
        type
        images
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListOrganizationCollectionsQuery__
 *
 * To run a query within a React component, call `useListOrganizationCollectionsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationCollectionsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationCollectionsQuery({
 *   variables: {
 *      organizationUuid: // value for 'organizationUuid'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListOrganizationCollectionsQuery(
  baseOptions: Apollo.QueryHookOptions<ListOrganizationCollectionsQuery, ListOrganizationCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOrganizationCollectionsQuery, ListOrganizationCollectionsQueryVariables>(
    ListOrganizationCollectionsDocument,
    options
  );
}
export function useListOrganizationCollectionsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationCollectionsQuery, ListOrganizationCollectionsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOrganizationCollectionsQuery, ListOrganizationCollectionsQueryVariables>(
    ListOrganizationCollectionsDocument,
    options
  );
}
export type ListOrganizationCollectionsQueryHookResult = ReturnType<typeof useListOrganizationCollectionsQuery>;
export type ListOrganizationCollectionsLazyQueryHookResult = ReturnType<typeof useListOrganizationCollectionsLazyQuery>;
export type ListOrganizationCollectionsQueryResult = Apollo.QueryResult<
  ListOrganizationCollectionsQuery,
  ListOrganizationCollectionsQueryVariables
>;
export const ListOrganizationShopsDocument = gql`
  query listOrganizationShops(
    $organizationUuid: String!
    $page: Int
    $limit: Int
    $sortBy: MyShopQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListMyShopsFilter
  ) {
    listOrganizationShops(
      organizationUuid: $organizationUuid
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        ownerUid
        createdAt
        updatedAt
        publish
        shopType
        organizationUuid
        siteSetting {
          title
          description
          banner
          favicon
          ogp
          category
          theme {
            thema {
              template
            }
            style {
              font
              textColor
              backgroundColor
              buttonColor
            }
            navi {
              pageTitle
              description
            }
          }
        }
        operation {
          type
          name
          detailName {
            lastName
            firstName
            lastNameKana
            firstNameKana
            lastNameEn
            firstNameEn
          }
          shopName {
            name
            nameKana
          }
          address {
            postalCode
            prefecture
            address
            addressDisplay
          }
          contact {
            phoneNumber
            publish
          }
          dayOfBirth
          sex
        }
        shopInformation {
          information
        }
        paymentMethod {
          secretKey
          publicKey
          enableCard
          baseCurrency
        }
        domain {
          name
          subDomain
          customDomain
        }
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListOrganizationShopsQuery__
 *
 * To run a query within a React component, call `useListOrganizationShopsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationShopsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationShopsQuery({
 *   variables: {
 *      organizationUuid: // value for 'organizationUuid'
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListOrganizationShopsQuery(
  baseOptions: Apollo.QueryHookOptions<ListOrganizationShopsQuery, ListOrganizationShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOrganizationShopsQuery, ListOrganizationShopsQueryVariables>(
    ListOrganizationShopsDocument,
    options
  );
}
export function useListOrganizationShopsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationShopsQuery, ListOrganizationShopsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOrganizationShopsQuery, ListOrganizationShopsQueryVariables>(
    ListOrganizationShopsDocument,
    options
  );
}
export type ListOrganizationShopsQueryHookResult = ReturnType<typeof useListOrganizationShopsQuery>;
export type ListOrganizationShopsLazyQueryHookResult = ReturnType<typeof useListOrganizationShopsLazyQuery>;
export type ListOrganizationShopsQueryResult = Apollo.QueryResult<
  ListOrganizationShopsQuery,
  ListOrganizationShopsQueryVariables
>;
export const ListOrganizationsDocument = gql`
  query listOrganizations(
    $page: Int
    $limit: Int
    $sortBy: OrganizationQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListOrganizationsFilter
  ) {
    listOrganizations(
      page: $page
      limit: $limit
      sortBy: $sortBy
      orderBy: $orderBy
      searchText: $searchText
      where: $where
    ) {
      items {
        uuid
        name
        status
        contactEmail
        createdAt
        updatedAt
        ipfsStorageApiKeys {
          storage
          apiKey
        }
        operation {
          type
          name
          detailName {
            lastName
            firstName
            lastNameKana
            firstNameKana
            lastNameEn
            firstNameEn
          }
          shopName {
            name
            nameKana
          }
          address {
            postalCode
            prefecture
            address
            addressDisplay
          }
          contact {
            phoneNumber
            publish
          }
          dayOfBirth
          sex
        }
        paymentSetting {
          secretKey
          publicKey
          requiredAcceptTerms
          policyUrl
          termsUrl
        }
        currencySetting {
          baseCurrency
        }
        masterWalletAddress
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListOrganizationsQuery__
 *
 * To run a query within a React component, call `useListOrganizationsQuery` and pass it any options that fit your needs.
 * When your component renders, `useListOrganizationsQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListOrganizationsQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListOrganizationsQuery(
  baseOptions?: Apollo.QueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(ListOrganizationsDocument, options);
}
export function useListOrganizationsLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListOrganizationsQuery, ListOrganizationsQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListOrganizationsQuery, ListOrganizationsQueryVariables>(
    ListOrganizationsDocument,
    options
  );
}
export type ListOrganizationsQueryHookResult = ReturnType<typeof useListOrganizationsQuery>;
export type ListOrganizationsLazyQueryHookResult = ReturnType<typeof useListOrganizationsLazyQuery>;
export type ListOrganizationsQueryResult = Apollo.QueryResult<ListOrganizationsQuery, ListOrganizationsQueryVariables>;
export const ListUsersDocument = gql`
  query listUsers(
    $page: Int
    $limit: Int
    $sortBy: UserQueryKey
    $orderBy: OrderBy
    $searchText: String
    $where: ListUsersFilter
  ) {
    listUsers(page: $page, limit: $limit, sortBy: $sortBy, orderBy: $orderBy, searchText: $searchText, where: $where) {
      items {
        uuid
        uid
        displayName
        email
        createdAt
      }
      pagination {
        totalItems
        itemsPerPage
        totalPages
        currentPage
      }
    }
  }
`;

/**
 * __useListUsersQuery__
 *
 * To run a query within a React component, call `useListUsersQuery` and pass it any options that fit your needs.
 * When your component renders, `useListUsersQuery` returns an object from Apollo Client that contains loading, error, and data properties
 * you can use to render your UI.
 *
 * @param baseOptions options that will be passed into the query, supported options are listed on: https://www.apollographql.com/docs/react/api/react-hooks/#options;
 *
 * @example
 * const { data, loading, error } = useListUsersQuery({
 *   variables: {
 *      page: // value for 'page'
 *      limit: // value for 'limit'
 *      sortBy: // value for 'sortBy'
 *      orderBy: // value for 'orderBy'
 *      searchText: // value for 'searchText'
 *      where: // value for 'where'
 *   },
 * });
 */
export function useListUsersQuery(baseOptions?: Apollo.QueryHookOptions<ListUsersQuery, ListUsersQueryVariables>) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
}
export function useListUsersLazyQuery(
  baseOptions?: Apollo.LazyQueryHookOptions<ListUsersQuery, ListUsersQueryVariables>
) {
  const options = { ...defaultOptions, ...baseOptions };
  return Apollo.useLazyQuery<ListUsersQuery, ListUsersQueryVariables>(ListUsersDocument, options);
}
export type ListUsersQueryHookResult = ReturnType<typeof useListUsersQuery>;
export type ListUsersLazyQueryHookResult = ReturnType<typeof useListUsersLazyQuery>;
export type ListUsersQueryResult = Apollo.QueryResult<ListUsersQuery, ListUsersQueryVariables>;
