import { env } from '~/env';
import { OrganizationStatus } from '~/graphql/admin/types';

export const ITEMS_PER_PAGE = 10;
export const API_MEDIA = env.REACT_APP_API_MEDIA + '/';
export const URL_REGEX = /https:\/\/(www.)?[A-Za-z0-9-]+(\.[A-Za-z]{2,}){1,3}\/?[^\s]*$/;

export const CHIP_COLOR: {
  [key: string]: 'default' | 'primary' | 'secondary' | 'error' | 'info' | 'success' | 'warning';
} = {
  [OrganizationStatus.Blocked]: 'error',
  [OrganizationStatus.Approved]: 'success',
  [OrganizationStatus.Requested]: 'warning',
};
