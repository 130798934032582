import React, { useCallback } from 'react';

import { yupResolver } from '@hookform/resolvers/yup';
import CloseIcon from '@mui/icons-material/Close';
import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import CircularProgress from '@mui/material/CircularProgress';
import Dialog, { DialogProps } from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Divider from '@mui/material/Divider';
import FormControlLabel from '@mui/material/FormControlLabel';
import TextField from '@mui/material/TextField';
import { JsonRpcProvider } from 'ethers';
import { useSnackbar } from 'notistack';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';
import * as yup from 'yup';

import DialogCloseButton from '../dialog-close-button';

import ImageUploadInput from '~/components/image-upload-input';
import { URL_REGEX } from '~/constants/common';
import { ListNetworksDocument, useCreateNetworkMutation } from '~/graphql/admin/types';
import { useNotify } from '~/hooks/useNotify';
import { StyledComponentProps } from '~/types/material-ui';
import { getErrorText } from '~/utils/yup.util';

const useStyles = makeStyles()(() => ({
  dialog: {
    '.MuiDialog-paper': {
      '& > .MuiIconButton-root': {
        top: '14px',
      },
      '.dialog-title': {
        padding: '16px',
        fontColor: '#444444',
        fontSize: '24px',
        fontWeight: '400',
        lineHeight: '133.4%',
      },
      '.MuiDivider-root': {
        margin: '0 16px',
        borderColor: '#9E9E9E',
      },
      '.MuiDialogContent-root': {
        padding: '16px',
        marginTop: '16px',
        '.MuiFormControl-root': {
          margin: 0,
          ':not(:first-of-type)': {
            marginTop: '23px',
          },
          '.MuiFormHelperText-root.Mui-error': {
            whiteSpace: 'pre-wrap',
          },
        },
      },
      '.MuiDialogActions-root': {
        padding: '16px',
        '.MuiButtonBase-root:not(:first-of-type)': {
          marginLeft: '16px',
        },
      },
    },
  },
}));

interface Props extends DialogProps {
  onClose: () => void;
  classes?: StyledComponentProps<typeof useStyles>['classes'] & DialogProps['classes'];
}

const AddNetworkDialog: React.FC<Props> = (props) => {
  const { open, onClose, ...others } = props;

  const { classes } = useStyles();

  return (
    <Dialog open={open} onClose={onClose} {...others} className={classes.dialog} maxWidth="sm" fullWidth>
      <Content {...props} />
    </Dialog>
  );
};

const schema = yup.object({
  name: yup.string().max(255).required(),
  testMode: yup.boolean().required(),
  iconFile: yup
    .mixed<FileList>()
    .nullable()
    .transform((value: FileList) => {
      if (value && value.length === 0) {
        return undefined;
      }
      return value;
    })
    .test({
      name: 'fileSize',
      message: 'form_validation.max_file_size',
      test: (value) => {
        if (!value) {
          return true;
        }
        return value[0].size < 10000000;
      },
    }),
  chainId: yup.string().max(100).required(),
  tokenSymbol: yup.string().max(100).required(),
  iconMock: yup.string().notRequired().nullable(),
  rpcUrl: yup.string().required().matches(URL_REGEX, { message: 'form_validation.invalid_value_entered' }),
  subgraphUrl: yup.string().required().matches(URL_REGEX, { message: 'form_validation.invalid_value_entered' }),
  blockExplorer: yup.string().required().matches(URL_REGEX, { message: 'form_validation.invalid_value_entered' }),
});

interface FormValues extends yup.InferType<typeof schema> {}

const Content: React.FC<Props> = (props) => {
  const { onClose } = props;

  const { t } = useTranslation();
  const { showError } = useNotify();
  const { enqueueSnackbar } = useSnackbar();

  const [createNetwork] = useCreateNetworkMutation({
    refetchQueries: [ListNetworksDocument],
  });

  const {
    control,
    handleSubmit,
    formState: { errors, isSubmitting },
  } = useForm<FormValues>({
    defaultValues: {
      name: '',
      rpcUrl: '',
      chainId: '',
      iconFile: null,
      iconMock: null,
      testMode: false,
      subgraphUrl: '',
      tokenSymbol: '',
      blockExplorer: '',
    },
    resolver: yupResolver(schema),
  });

  const onSubmit = useCallback(
    async (data: FormValues) => {
      try {
        try {
          const provider = new JsonRpcProvider(data.rpcUrl);
          const network = await provider.getNetwork();
          const chainId = Number(network.chainId);
          if (chainId !== Number(data.chainId)) {
            enqueueSnackbar(t('toast_message.chain_id_is_incorrect'), { variant: 'error' });
            return;
          }
        } catch {
          enqueueSnackbar(t('toast_message.failed_connect_rpc'), { variant: 'error' });
          return;
        }
        await createNetwork({
          variables: {
            input: {
              name: data.name,
              rpcUrl: data.rpcUrl,
              contractAddress: '',
              chainId: data.chainId,
              testMode: data.testMode,
              iconFile: data.iconFile,
              tokenName: data.tokenSymbol,
              subgraphUrl: data.subgraphUrl,
              tokenSymbol: data.tokenSymbol,
              tokenSymbols: [data.tokenSymbol],
              blockExplorer: data.blockExplorer,
            },
          },
        });
        enqueueSnackbar(t('toast_message.add_network_successfully'), { variant: 'success' });
        onClose();
      } catch (error: any) {
        showError(error);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [createNetwork, onClose, enqueueSnackbar, t]
  );

  return (
    <>
      <DialogCloseButton onClick={onClose} data-testid="close-button">
        <CloseIcon />
      </DialogCloseButton>
      <DialogTitle className="dialog-title">{t('add_network')}</DialogTitle>
      <Divider />
      <DialogContent>
        <ImageUploadInput<FormValues>
          required
          type="image"
          height="156px"
          name="iconFile"
          control={control}
          label={t('image')}
          sx={{ margin: 0 }}
          disabled={isSubmitting}
          defaultImageUrlName="iconMock"
          error={!!errors.iconFile?.message}
          helperText={t(errors.iconFile?.message as any, {
            size: 10,
          })}
        />
        <Controller
          name="name"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ marginTop: 0 }}
              label={t('name.thing')}
              error={!!errors.name?.message}
              helperText={getErrorText(errors.name?.message, t)}
              disabled={isSubmitting}
              {...field}
            />
          )}
        />
        <Controller
          name="chainId"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ marginTop: 0 }}
              label={t('chain_id')}
              error={!!errors.chainId?.message}
              helperText={getErrorText(errors.chainId?.message, t)}
              disabled={isSubmitting}
              {...field}
            />
          )}
        />
        <Controller
          name="rpcUrl"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              label="RPC URL"
              margin="normal"
              variant="outlined"
              sx={{ marginTop: 0 }}
              placeholder="https://domain.com"
              error={!!errors.rpcUrl?.message}
              helperText={t(errors.rpcUrl?.message as any)}
              disabled={isSubmitting}
              {...field}
            />
          )}
        />
        <Controller
          name="blockExplorer"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ marginTop: 0 }}
              label={t('block_explorer')}
              placeholder="https://domain.com"
              error={!!errors.blockExplorer?.message}
              helperText={t(errors.blockExplorer?.message as any)}
              disabled={isSubmitting}
              {...field}
            />
          )}
        />
        <Controller
          name="tokenSymbol"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ marginTop: 0 }}
              label={t('token_symbol')}
              error={!!errors.tokenSymbol?.message}
              helperText={getErrorText(errors.tokenSymbol?.message, t)}
              disabled={isSubmitting}
              {...field}
            />
          )}
        />
        <Controller
          name="subgraphUrl"
          control={control}
          render={({ field }) => (
            <TextField
              fullWidth
              margin="normal"
              variant="outlined"
              sx={{ marginTop: 0 }}
              label={t('subgraph_url')}
              placeholder="https://domain.com"
              error={!!errors.subgraphUrl?.message}
              helperText={t(errors.subgraphUrl?.message as any)}
              disabled={isSubmitting}
              {...field}
            />
          )}
        />
        <Box marginTop="16px">
          <Controller
            name="testMode"
            control={control}
            render={({ field }) => (
              <FormControlLabel control={<Checkbox checked={field.value} {...field} />} label={t('test_mode')} />
            )}
          />
        </Box>
      </DialogContent>
      <DialogActions>
        <Button disabled={isSubmitting} color="primary" variant="outlined" onClick={onClose}>
          {t('cancel')}
        </Button>
        <Button
          color="primary"
          variant="contained"
          disabled={isSubmitting}
          endIcon={isSubmitting && <CircularProgress size={20} color="inherit" />}
          onClick={handleSubmit(onSubmit)}
        >
          {t('add')}
        </Button>
      </DialogActions>
    </>
  );
};

export default AddNetworkDialog;
