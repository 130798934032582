type EnvType = {
  REACT_APP_FIREBASE_CLIENT_CREDENTIALS: string;
  REACT_APP_PROJECT_ID_WALLET_CONNECT: string;
  REACT_APP_CLIENT_URL: string;
  REACT_APP_API_ADMIN_SERVER: string;
  REACT_APP_API_MEDIA: string;
  REACT_APP_IPFS_GATEWAY_URL: string;
  REACT_APP_HUBSPOT_URL: string;
};
export const env: EnvType = process.env.NODE_ENV === 'production' ? (window as any)['env'] : process.env;
