import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import Chip from '@mui/material/Chip';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useSnackbar } from 'notistack';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import EditPlanDialog from '~/components/dialog/edit-plan-dialog';
import ListTable from '~/components/list-table';
import GridToolbarSearchByAPI from '~/components/list-table/GridToolbarSearchByAPI';
import WrapperWithFab from '~/components/WrapperWithFab';
import { PlansQueryKey } from '~/enum/common';
import { GetPlansDocument, useGetPlansQuery, useUpdatePlanMutation } from '~/graphql/admin/types';
import useDebounce from '~/hooks/useDebounce';
import { IPlan } from '~/interfaces/common';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

enum STATUS {
  SHOW = 'SHOW',
  HIDE = 'HIDE',
}

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
}));

const initQuery = {
  searchText: '',
  orderBy: verifyOrderKey(getLocalStorage('plans_list_order')),
  sortBy: verifySortKey(PlansQueryKey, getLocalStorage('plans_list_sort'), PlansQueryKey.MonthlyFee),
};

const PlansManagement = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();
  const { enqueueSnackbar } = useSnackbar();

  const [search, setSearch] = useState('');
  const debounceValue = useDebounce(search, 500);

  const [currentPlan, setCurrentPlan] = useState<IPlan>();
  const [plansQuery, setPlansQuery] = useState(initQuery);
  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [processingPlans, setProcessingPlans] = useState(false);

  const [updatePlan] = useUpdatePlanMutation({
    refetchQueries: [GetPlansDocument],
  });
  const { data: listPlansRes, loading: loadingListPlans } = useGetPlansQuery({
    fetchPolicy: 'cache-and-network',
  });
  const listPlans = useMemo(() => {
    setProcessingPlans(true);
    const filtered =
      listPlansRes?.getPlans.filter((i) =>
        (i.planName || '').toLowerCase().includes((debounceValue || '').toLowerCase())
      ) || [];
    const result = filtered.map((plan) => ({
      id: plan.uuid,
      [PlansQueryKey.Uuid]: plan.uuid,
      [PlansQueryKey.PlanName]: plan.planName,
      [PlansQueryKey.CreatedAt]: plan.createdAt,
      [PlansQueryKey.MonthlyFee]: plan.monthlyFee || 0,
      [PlansQueryKey.NumberOfShops]: plan.numberOfShops || 0,
      [PlansQueryKey.AdditionalUserFee]: plan.additionalUserFee || 0,
      [PlansQueryKey.AdditionalShopFee]: plan.additionalShopFee || 0,
      [PlansQueryKey.AdditionalSiteFee]: plan.additionalSiteFee || 0,
      [PlansQueryKey.NumberOfAdminUsers]: plan.numberOfAdminUsers || 0,
      [PlansQueryKey.AvailableForSubscribe]: plan.availableForSubscribe,
      [PlansQueryKey.NumberOfMemberSites]: plan.numberOfMemberSites || 0,
      [PlansQueryKey.AdditionalMemberFee]: plan.additionalMemberFee || 0,
      [PlansQueryKey.NumberOfMembersPerSite]: plan.numberOfMemberPerSite || 0,
    }));
    setProcessingPlans(false);
    return result;
  }, [listPlansRes?.getPlans, debounceValue]);

  useEffect(() => {
    document.title = t('settings.plans_management.title');
  }, [t]);

  useEffect(() => {
    setLocalStorageItems({
      plans_list_order: plansQuery?.orderBy,
      plans_list_sort: plansQuery?.sortBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [plansQuery?.orderBy, plansQuery?.sortBy]);

  const updatePlansQuery = (newValue: any) => setPlansQuery((value: any) => ({ ...value, ...newValue }));

  const handleOpen = () => setOpenEditDialog(true);

  const handleClose = () => setOpenEditDialog(false);

  const handleOpenDialog = (plan?: IPlan) => {
    handleOpen();
    setCurrentPlan(plan || undefined);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    setSearch(e.target.value);
  };

  const checkStatusNft = (status: STATUS) => {
    if (status === STATUS.HIDE) {
      return <Chip label={t('hide')} color="error" />;
    } else {
      return <Chip label={t('show')} color="success" />;
    }
  };

  const onChangeStatus = async (row: IPlan, value: STATUS) => {
    try {
      setProcessingPlans(true);
      await updatePlan({
        variables: {
          input: {
            uuid: row.UUID,
            availableForSubscribe: value === STATUS.SHOW,
          },
        },
      });
      enqueueSnackbar(t('toast_message.updated_successfully'), { variant: 'success' });
    } catch {}
    setProcessingPlans(false);
  };

  const columns: GridColDef[] = useMemo(
    () => [
      {
        width: 180,
        field: PlansQueryKey.PlanName,
        headerName: t('settings.plans_management.plan_name'),
      },
      {
        width: 125,
        headerName: t('status'),
        field: PlansQueryKey.AvailableForSubscribe,
        renderCell: ({ row }) => {
          return (
            <Select
              fullWidth
              disableUnderline
              variant="standard"
              value={row[PlansQueryKey.AvailableForSubscribe] ? STATUS.SHOW : STATUS.HIDE}
              onChange={(e) => onChangeStatus(row, e.target.value as STATUS)}
            >
              {Object.values(STATUS).map((status, idx) => (
                <MenuItem key={idx} value={status}>
                  {checkStatusNft(status)}
                </MenuItem>
              ))}
            </Select>
          );
        },
      },
      {
        width: 120,
        field: PlansQueryKey.MonthlyFee,
        headerName: t('settings.plans_management.plan_price'),
        valueFormatter: ({ value }) => `${value}$`,
      },
      {
        width: 120,
        field: PlansQueryKey.NumberOfAdminUsers,
        headerName: t('settings.plans_management.number_of_free_users'),
      },
      {
        width: 120,
        field: PlansQueryKey.NumberOfShops,
        headerName: t('settings.plans_management.number_of_free_shops'),
      },
      {
        width: 120,
        field: PlansQueryKey.NumberOfMemberSites,
        headerName: t('settings.plans_management.number_of_free_sites'),
      },
      {
        width: 120,
        field: PlansQueryKey.NumberOfMembersPerSite,
        headerName: t('settings.plans_management.number_of_free_members_per_site'),
      },
      {
        width: 120,
        field: PlansQueryKey.AdditionalUserFee,
        headerName: t('settings.plans_management.additional_fee_per_user'),
        valueFormatter: ({ value }) => `${value}$`,
      },
      {
        width: 120,
        field: PlansQueryKey.AdditionalShopFee,
        headerName: t('settings.plans_management.additional_fee_per_shop'),
        valueFormatter: ({ value }) => `${value}$`,
      },
      {
        width: 120,
        field: PlansQueryKey.AdditionalSiteFee,
        headerName: t('settings.plans_management.additional_fee_per_site'),
        valueFormatter: ({ value }) => `${value}$`,
      },
      {
        width: 120,
        field: PlansQueryKey.AdditionalMemberFee,
        headerName: t('settings.plans_management.additional_fee_per_member'),
        valueFormatter: ({ value }) => `${value}$`,
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: PlansQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 120,
        headerName: '',
        type: 'actions',
        field: t('actions'),
        disableReorder: true,
        renderCell: ({ row }) => {
          return [
            <Button key="1" variant="contained" onClick={() => handleOpenDialog(row)}>
              {t('settings.plans_management.edit_plan')}
            </Button>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const toolbarProps = {
    search,
    searchLabel: `${t('name.person')}...`,
    handleSearch,
  };

  return (
    <Box className={classes.wrapper}>
      <WrapperWithFab onClick={() => handleOpenDialog()}>
        <CustomCardTable
          cardTitle={t('settings.plans_management.title')}
          cardContent={
            <Box>
              <ListTable
                noBorder
                rows={listPlans}
                columns={columns}
                pagination={false}
                sortingMode="client"
                rowCount={listPlans.length}
                tableName="plansManagement"
                isLoading={loadingListPlans || processingPlans}
                sort={{
                  sortBy: plansQuery.sortBy,
                  orderBy: plansQuery.orderBy,
                }}
                slots={{
                  toolbar: GridToolbarSearchByAPI,
                }}
                slotProps={{
                  toolbar: toolbarProps,
                }}
                onSort={updatePlansQuery}
              />
            </Box>
          }
        />
      </WrapperWithFab>
      <EditPlanDialog open={openEditDialog} values={currentPlan} onClose={handleClose} />
    </Box>
  );
};

export default PlansManagement;
