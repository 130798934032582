import { memo, useEffect, useMemo } from 'react';

import Box from '@mui/material/Box';
import Card from '@mui/material/Card';
import CardContent from '@mui/material/CardContent';
import Grid from '@mui/material/Grid';
import Stack from '@mui/material/Stack';
import Typography from '@mui/material/Typography';
import { getAddress } from 'ethers';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import UserLayout from '~/components/app-layout/user-layout';
import { IconBtnCopy } from '~/components/IconBtnCopy';
import LoaderCenter from '~/components/loader-center';
import { useCountAllQuery, useGetMasterWalletAddressQuery } from '~/graphql/admin/types';
import { useFirebaseUser } from '~/hooks/with-firebase-auth';

const useStyles = makeStyles()((theme) => ({
  content: {
    height: '100%',
    width: '100%',
  },
  cardContent: {
    padding: 24,
  },
  contentHeader: {
    fontSize: 20,
    fontWeight: 500,
    color: '#333333',
  },
  contentText: {
    fontSize: 16,
    color: '#0000008A',
    fontWeight: 400,
    marginTop: '8px',
  },
}));

const getTitles = (t: Function): { [key: string]: string } => ({
  totalShop: t('dashboard.total_shops'),
  totalAccount: t('dashboard.total_users'),
  totalCollection: t('dashboard.total_collections'),
  totalMemberSite: t('dashboard.total_member_sites'),
  totalOrganization: t('dashboard.total_organizations'),
});

const Dashboard: React.FC = () => {
  const { t } = useTranslation();
  const firebaseUser = useFirebaseUser();
  const { classes } = useStyles(undefined, { props: {} });

  useEffect(() => {
    document.title = t('dashboard.title');
  }, [t]);

  const { data: countAllRes, loading: loadingCountAll } = useCountAllQuery({
    fetchPolicy: 'cache-and-network',
  });
  const { data: masterWalletAddressRes } = useGetMasterWalletAddressQuery();
  const masterWalletAddress = masterWalletAddressRes?.getMasterWalletAddress
    ? getAddress(masterWalletAddressRes?.getMasterWalletAddress)
    : '';

  const items = useMemo(() => {
    const titles = getTitles(t);
    const countAll: { [key: string]: number } = countAllRes?.countAll!;
    if (!countAll) {
      return [];
    }
    return Object.keys(countAll).map((key) => ({
      title: titles[key],
      number: countAll[key],
    }));
  }, [t, countAllRes?.countAll]);

  return (
    <UserLayout>
      <Box className={classes.content}>
        <Typography variant="h5">{t('dashboard.welcome', { name: firebaseUser?.displayName })}</Typography>
        <Typography variant="caption">
          {t('dashboard.last_login')}: {moment(firebaseUser?.metadata.lastSignInTime).format(t('date_format'))}
        </Typography>
        <Typography variant="h6" marginTop="32px">
          {t('master_wallet_address')}
        </Typography>
        <Box gap="4px" display="flex" alignItems="center">
          <Typography variant="body1">{masterWalletAddress}</Typography>
          <IconBtnCopy text={masterWalletAddress || '-'} />
        </Box>
        {loadingCountAll ? (
          <LoaderCenter />
        ) : (
          <Grid container columnSpacing={2} rowSpacing={2} marginTop={2}>
            {items.map((item, key) => (
              <Grid key={key} item xs={12} md={6}>
                <Card>
                  <CardContent>
                    <Stack direction="column" spacing={2}>
                      <Typography variant="h6" textAlign="center">
                        {item.title}
                      </Typography>
                      <Typography variant="h3" textAlign="center" color="primary">
                        {item.number.toLocaleString()}
                      </Typography>
                    </Stack>
                  </CardContent>
                </Card>
              </Grid>
            ))}
          </Grid>
        )}
      </Box>
    </UserLayout>
  );
};

export default memo(Dashboard);
