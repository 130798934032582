import { ChangeEvent, useEffect, useMemo, useState } from 'react';

import Box from '@mui/material/Box';
import Button from '@mui/material/Button';
import { GridColDef } from '@mui/x-data-grid-pro';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import { makeStyles } from 'tss-react/mui';

import CustomCardTable from '~/components/custom-card-table';
import EditRevenueShareDialog from '~/components/dialog/edit-revenue-share-dialog';
import ListTable from '~/components/list-table';
import GridToolbarSearchByAPI from '~/components/list-table/GridToolbarSearchByAPI';
import WrapperWithFab from '~/components/WrapperWithFab';
import { RevenueShareQueryKey } from '~/enum/common';
import { OrderBy, RevenueShareUnit, useGetRevenueShareQuery } from '~/graphql/admin/types';
import useDebounce from '~/hooks/useDebounce';
import { IRevenueShare } from '~/interfaces/common';
import { getLocalStorage, setLocalStorageItems, verifyOrderKey, verifySortKey } from '~/utils/common';

const useStyles = makeStyles()(() => ({
  wrapper: {
    width: '100%',
    paddingBottom: '60px',
  },
}));

const initQuery = {
  searchText: '',
  orderBy: verifyOrderKey(getLocalStorage('revenue_share_order') || OrderBy.Asc),
  sortBy: verifySortKey(RevenueShareQueryKey, getLocalStorage('revenue_share_sort'), RevenueShareQueryKey.From),
};

const RevenueShare = () => {
  const { t } = useTranslation();
  const { classes } = useStyles();

  const [search, setSearch] = useState('');
  const debounceValue = useDebounce(search, 500);

  const [openEditDialog, setOpenEditDialog] = useState(false);
  const [revenueShareQuery, setRevenueShareQuery] = useState(initQuery);
  const [processingRevenueShare, setProcessingRevenueShare] = useState(false);
  const [currentRevenueShare, setCurrentRevenueShare] = useState<IRevenueShare>();

  const { data: revenueShareRes, loading: loadingRevenueShare } = useGetRevenueShareQuery({
    fetchPolicy: 'cache-and-network',
  });
  const listRevenueShare = useMemo(() => {
    setProcessingRevenueShare(true);
    const revenueShare = revenueShareRes?.getRevenueShare || [];
    const result = revenueShare.map((range, idx) => {
      const nextRange = revenueShare[idx + 1];
      const to = !!nextRange ? nextRange.from - (nextRange.from <= 100 ? 0.01 : 1) : Infinity;
      return {
        id: range.uuid,
        [RevenueShareQueryKey.To]: to,
        [RevenueShareQueryKey.Fee]: range.fee,
        [RevenueShareQueryKey.From]: range.from,
        [RevenueShareQueryKey.Unit]: range.unit,
        [RevenueShareQueryKey.CreatedAt]: range.createdAt,
      };
    });
    const filtered = result.filter(
      (i) => debounceValue === '' || i[RevenueShareQueryKey.From].toString() === debounceValue.toLowerCase()
    );
    setProcessingRevenueShare(false);
    return filtered;
  }, [revenueShareRes?.getRevenueShare, debounceValue]);

  useEffect(() => {
    document.title = t('settings.revenue_share.title');
  }, [t]);

  useEffect(() => {
    setLocalStorageItems({
      revenue_share_sort: revenueShareQuery?.sortBy,
      revenue_share_order: revenueShareQuery?.orderBy,
    });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [revenueShareQuery?.orderBy, revenueShareQuery?.sortBy]);

  const updateRevenueShareQuery = (newValue: any) => setRevenueShareQuery((value: any) => ({ ...value, ...newValue }));

  const handleOpen = () => setOpenEditDialog(true);

  const handleClose = () => setOpenEditDialog(false);

  const handleOpenDialog = (revenueShare?: IRevenueShare) => {
    handleOpen();
    setCurrentRevenueShare(revenueShare || undefined);
  };

  const handleSearch = (e: ChangeEvent<HTMLInputElement>) => {
    const searchText = e.target.value.replace(/,/g, '');
    setSearch(searchText);
  };

  const columns: GridColDef<IRevenueShare>[] = useMemo(
    () => [
      {
        width: 130,
        headerName: t('from'),
        field: RevenueShareQueryKey.From,
        valueFormatter: ({ value }) =>
          '$' + value.toLocaleString(undefined, { minimumFractionDigits: value <= 100 ? 2 : 0 }),
      },
      {
        width: 130,
        sortable: false,
        headerName: t('to'),
        field: RevenueShareQueryKey.To,
        valueFormatter: ({ value }) => (isFinite(value) ? '$' + value.toLocaleString() : '∞'),
      },
      {
        width: 130,
        sortable: false,
        headerName: t('fee'),
        field: RevenueShareQueryKey.Fee,
        renderCell: ({ row, value }) =>
          row[RevenueShareQueryKey.Unit] === RevenueShareUnit.Usd ? `$${value}` : `${value}%`,
      },
      {
        width: 120,
        type: 'date',
        headerName: t('created_at'),
        field: RevenueShareQueryKey.CreatedAt,
        valueFormatter: ({ value }) => {
          return value ? moment(value).format(t('date_format')) : '-';
        },
      },
      {
        width: 140,
        headerName: '',
        type: 'actions',
        field: t('actions'),
        disableReorder: true,
        renderCell: ({ row }) => {
          return [
            <Button key="1" variant="contained" onClick={() => handleOpenDialog(row)}>
              {t('edit')}
            </Button>,
          ];
        },
      },
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [t]
  );

  const toolbarProps = {
    search,
    searchLabel: `${t('from')}...`,
    handleSearch,
  };

  return (
    <Box className={classes.wrapper}>
      <WrapperWithFab onClick={() => handleOpenDialog()}>
        <CustomCardTable
          cardTitle={t('settings.revenue_share.title')}
          cardContent={
            <Box>
              <ListTable
                noBorder
                columns={columns}
                pagination={false}
                sortingMode="client"
                rows={listRevenueShare}
                tableName="revenueShare"
                rowCount={listRevenueShare.length}
                isLoading={loadingRevenueShare || processingRevenueShare}
                sort={{
                  sortBy: revenueShareQuery.sortBy,
                  orderBy: revenueShareQuery.orderBy,
                }}
                slots={{
                  toolbar: GridToolbarSearchByAPI,
                }}
                slotProps={{
                  toolbar: toolbarProps,
                }}
                onSort={updateRevenueShareQuery}
              />
            </Box>
          }
        />
      </WrapperWithFab>
      <EditRevenueShareDialog open={openEditDialog} values={currentRevenueShare} onClose={handleClose} />
    </Box>
  );
};

export default RevenueShare;
